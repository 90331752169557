/* Do Not Disturb section */
.do-not-disturb-section {
  border-top: 1px solid #e5e7eb;
  padding-top: 1.5rem;
}

.dnd-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.dnd-title {
  font-weight: 500;
  color: #111827;
}

.dnd-description {
  font-size: 0.875rem;
  color: #6b7280;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e7eb;
  transition: 0.4s;
  border-radius: 1rem;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 1.25rem;
  left: 0.125rem;
  bottom: 0.125rem;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3b82f6;
}

input:checked + .slider:before {
  transform: translateX(1.5rem);
}

/* DND Settings */
.dnd-settings {
  margin-top: 1rem;
}

.time-inputs {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 640px) {
  .time-inputs {
    grid-template-columns: repeat(2, 1fr);
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.input-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: #374151;
  appearance: none;
  background-color: white;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.input[type="time"] {
  min-width: 120px;
  height: 38px;
}

/* Weekdays */
.weekdays-section {
  margin-top: 1rem;
}

.weekdays-section label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.weekdays-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
}

@media (min-width: 768px) {
  .weekdays-grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

.weekday-button {
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  background-color: #22c55e;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.9;
}

.weekday-button:hover {
  opacity: 1;
}

.weekday-button.selected {
  background-color: #dc2626;
  border-color: #dc2626;
  color: #fef2f2;
  opacity: 0.6;
  text-decoration: line-through;
}

.weekday-button.selected:hover {
  opacity: 0.7;
}
