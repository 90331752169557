@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import "../../theme/variables.css";

.containerAll {
  position: relative;
  background-color: var(--color-bg);
  height: var(--container-height);
  min-height: 100vh;
}

.containerAll .ondas {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  pointer-events: none;
}

.main-content {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
  margin: 0 0 0 250px;
  padding-top: var(--header-height);
  overflow: hidden;
  z-index: var(--z-index-main);
}

.main-contentWIzard {
  width: 100%;
  display: flex;
 justify-content: center;
  /* flex-direction: column; */
  /* margin: 0 0 0 250px; */
  padding-top: var(--header-height);
  overflow: hidden;
  z-index: var(--z-index-main);
}

.footerApp {
  font-family: var(--font-family);
  font-size: var(--font-size-lg);
  font-weight: 400;
  line-height: var(--line-height);
  text-align: center;
  padding: var(--spacing-md);
  color: var(--color-primary);
}
