@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerDashboard {
  padding: var(--spacing-lg);
  display: flex;
  gap: var(--spacing-lg);
}

.containerDashboard .containerLeft,
.containerDashboard .containerRigh {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.containerDashboard .containerRigh {
  flex: 1.5;
}

.containerDashboard .containerLeft {
  flex: 2;
}

.containerDashboard .line {
  height: 100%;
  width: 2px;
  background-color: var(--border-color);
}

.containerDashboard .containerHeader {
  display: flex;
  flex-direction: column;
}

.containerDashboard .containerHeader .title,
.containerDashboard .containerHeader .text {
  font-family: var(--font-family);
  text-align: left;
  color: var(--color-primary);
}

.containerDashboard .containerHeader .title {
  font-weight: 700;
  font-size: var(--font-size-title);
}

.containerDashboard .containerHeader .text {
  font-weight: 400;
  font-size: var(--font-size-lg);
}

.containerDashboard .containerInfosLeadsSends {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  color: var(--color-primary);
  font-weight: 400;
  font-size: var(--font-size-large-text);
  text-align: center;
  gap: var(--spacing-lg);
}

.containerDashboard .containerInfosLeadsSends .containerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: var(--spacing-lg);
  gap: var(--spacing-md);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerDashboard .containerInfosLeadsSends .containerInfo:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
}

.containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
  font-weight: 700;
  font-size: var(--info-textimg-font-size-desktop);
  gap: var(--spacing-md);
  display: flex;
  align-items: center;
}

.containerDashboard .containerTokens {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
  gap: var(--spacing-md);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
}

.linkInvite {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-lg);
  gap: var(--spacing-md);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  margin-top: var(--spacing-lg);
}

.containerDashboard .containerTokens .containerHeaderTokens {
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-md);
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.containerDashboard .containerTokens .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20px;
  justify-content: flex-end;
}

.containerDashboard .containerTokens .fill {
  background-color: var(--bg-light);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  height: 100%;
}

.containerDashboard .containerTokens .leads {
  background-color: var(--color-success);
  display: flex;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  color: var(--color-primary);
  height: 100%;
  transition: width var(--graph-transition-speed) ease;
}

.containerAlertGoogle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: var(--color-white);
  border: 2px solid var(--color-error);
  padding: var(--spacing-md);
  border-radius: var(--card-border-radius);
  gap: var(--spacing-lg);
}

.containerAlertGoogle .containerText {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
}

.containerAlertGoogle .text {
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.containerAlertGoogle .button {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
  background: var(--color-success);
  cursor: pointer;
  padding: var(--spacing-md);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerAlertGoogle .button:hover {
  opacity: var(--hover-opacity);
}

.containerHeader .link {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--bg-light);
  border: none;
  padding: 0;
  font-size: var(--font-size-lg);
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: var(--font-family);
  color: var(--color-primary);
  text-align: center;
}

@media only screen and (max-width: 1920px) {
  .containerDashboard .containerHeader .title {
    font-size: var(--font-size-title);
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: var(--info-textimg-font-size-mobile);
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerTokens .leads {
    font-size: var(--font-size-sm);
  }
  
  .containerAlertGoogle .text,
  .containerAlertGoogle .button {
    font-size: var(--font-size-lg);
  }
  
  .containerAlertGoogle .button {
    border-radius: var(--input-border-radius);
  }
}

@media only screen and (max-width: 1319px) {
  .containerDashboard {
    flex-direction: column;
  }

  .containerDashboard .line {
    display: none;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard {
    padding: var(--spacing-lg);
    gap: var(--spacing-md);
  }

  .containerDashboard .containerLeft,
  .containerDashboard .containerRigh {
    gap: var(--spacing-md);
  }

  .containerDashboard .containerHeader .title {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: var(--font-size-sm);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo img {
    width: var(--clients-icon-btn-size);
  }

  .containerDashboard .containerTokens {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: var(--font-size-text);
  }

  .containerDashboard .containerTokens .leads {
    font-size: var(--font-size-sm);
  }

  .containerAlertGoogle .text,
  .containerAlertGoogle .button {
    font-size: var(--font-size-base);
  }

  .containerAlertGoogle .button img {
    width: var(--button-icon-width);
  }

  .containerHeader .link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: var(--clients-button-max-width);
  }
}
