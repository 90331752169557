/* Card Base */
.geist-card {
  --geist-card-bg: var(--color-white);
  --geist-card-border: var(--border-color);
  --geist-label-color: var(--color-secondary);
  --geist-value-color: var(--color-dark);
  
  background-color: var(--geist-card-bg);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 100%;
}

/* Card Header */
.geist-card-header {
  display: flex;
  align-items: center;
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--border-color);
  background-color: var(--color-primary);
  color: var(--color-white);
}

/* Avatar genérico */
.geist-avatar {
  width: 36px;
  height: 36px;
  border-radius: var(--border-radius-circle);
  background-color: var(--color-success);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-md);
}

.geist-avatar img {
  width: 24px;
  height: 24px;
}

/* Título do card */
.geist-card-title {
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin: 0;
}

/* Conteúdo do card */
.geist-card-content {
  padding: var(--spacing-lg);
}

/* Grupo de informações */
.geist-info-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

/* Item de informação */
.geist-info-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

/* Rótulo genérico */
.geist-label {
  font-size: var(--font-size-sm);
  color: var(--geist-label-color);
  opacity: 0.8;
  font-weight: 500;
}

/* Valor genérico */
.geist-value {
  font-size: var(--font-size-base);
  color: var(--geist-value-color);
  font-weight: 600;
}

/* Responsividade */
@media (min-width: 768px) {
  .geist-info-item {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: var(--spacing-md);
  }
  
  .geist-info-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  
  .geist-label {
    font-size: var(--font-size-base);
    flex: 1;
  }
  
  .geist-value {
    flex: 2;
    text-align: right;
  }
}

/* Variantes de cores para o header */
.geist-card-header.primary {
  background-color: var(--color-primary);
}

.geist-card-header.secondary {
  background-color: var(--color-secondary);
}

.geist-card-header.success {
  background-color: var(--color-success);
}

/* Variantes de layout */
.geist-card.compact .geist-card-content {
  padding: var(--spacing-md);
}

.geist-card.no-padding .geist-card-content {
  padding: 0;
}

/* Tema escuro */
.geist-card.dark {
  --geist-card-bg: var(--color-dark);
  --geist-card-border: rgba(255, 255, 255, 0.1);
  --geist-label-color: rgba(255, 255, 255, 0.7);
  --geist-value-color: var(--color-white);
  
  background-color: var(--geist-card-bg);
  border: 1px solid var(--geist-card-border);
}

.geist-card.dark .geist-label {
  color: var(--geist-label-color);
}

.geist-card.dark .geist-value {
  color: var(--geist-value-color);
}