/* Container and Card styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.5rem;
}

/* Review Content */
.review-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.review-header {
  text-align: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.review-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #111827;
}

.review-subtitle {
  color: #6b7280;
}

/* Sections */
.sections-grid {
  display: grid;
  gap: 1rem;
}

.section-container {
  padding: 1rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.icon-container {
  padding: 0.5rem;
  background-color: white;
  border-radius: 0.375rem;
}

.section-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #3b82f6;
}

.section-title {
  font-weight: 500;
  color: #111827;
}

/* Info Boxes */
.info-box {
  background-color: white;
  padding: 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
}

.info-box + .info-box {
  margin-top: 0.75rem;
}

.info-title {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.info-text {
  font-size: 0.875rem;
  color: #6b7280;
  white-space: pre-wrap;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  gap: 5px;
}

.info-label {
  color: #374151;
}

.info-value {
  font-weight: 500;
  color: #111827;
}

.info-detail {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.settings-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

/* Action Buttons */
.step4-action-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 200px;
}

.button-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.button-primary {
  background-color: #22c55e;
  border: none;
  color: white;
}

.button-primary:hover {
  background-color: #16a34a;
}

.button-outline {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
}

.button-outline:hover {
  background-color: #f9fafb;
}
