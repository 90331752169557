.containerStep4 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  gap: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep4:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerStep4 .containerInputs {
  display: flex;
  flex: 1;
  gap: var(--spacing-lg);
  position: relative;
}

.containerStep4 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  justify-content: space-between;
  background-color: var(--color-bg);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerStep4 .containerInputs .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  font-size: var(--font-size-title);
  font-weight: 400;
  user-select: none;
  position: relative;
  margin-bottom: var(--spacing-md);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.checkbox-container:hover {
  color: var(--color-success);
}

.checkbox-container input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.checkmarkBackground {
  height: var(--clients-icon-btn-size);
  width: var(--clients-icon-btn-size);
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  transition: var(--transition-base);
}

.checkbox-container:hover .checkmarkBackground {
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

.checkmark {
  height: 16px;
  width: 16px;
  background-color: var(--color-bg);
  border-radius: var(--border-radius);
  transition: var(--transition-base);
}

.checkbox-container input:checked ~ .checkmark {
  background-color: var(--color-success);
  transform: scale(1.1);
}

.checkmark:after {
  content: "";
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

@media only screen and (max-width: 1153px) {
  .containerStep4 {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .containerStep4 .containerInputs {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .containerStep4 .containerInputs .card {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
  
  .checkbox-container {
    font-size: var(--font-size-text);
  }
  
  .checkmarkBackground {
    height: var(--clients-icon-btn-size);
    width: var(--clients-icon-btn-size);
  }
  
  .checkmark {
    height: 16px;
    width: 16px;
  }
}
