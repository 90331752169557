.card {
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1)!important;
  padding: 16px;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--color-primary);
}

.card-content {
  font-size: 14px;
  padding: 0!important;
}
