/* Stepper styles */
.w-full {
  width: 100%;
}

.mb-8 {
  margin-bottom: 2rem;
}

.stepper-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto 2rem;
  width: 100%;
  padding: 0 2rem;
}

.progress-line {
  position: absolute;
  left: 2rem;
  right: 2rem;
  top: 1.5rem;
  height: 2px;
  background-color: #E2E8F0;
  z-index: 0;
}

.progress-fill {
  height: 100%;
  background-color: #4361EE;
  transition: width 0.3s ease;
}

.step-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #E2E8F0;
  color: #94A3B8;
  transition: all 0.3s ease;
  margin-bottom: 0.75rem;
  font-weight: 500;
}

.step-icon.active {
  background-color: #4361EE;
  border-color: #4361EE;
  color: white;
  box-shadow: 0 0 0 4px rgba(67, 97, 238, 0.15);
}

.step-icon.completed {
  background-color: #4361EE;
  border-color: #4361EE;
  color: white;
}

.icon {
  width: 20px;
  height: 20px;
}

.step-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #64748B;
  transition: color 0.3s ease;
  text-align: center;
  white-space: nowrap;
}

.step-title.active {
  color: #4361EE;
  font-weight: 600;
}
