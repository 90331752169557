.containerFinancePartner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.headerFinancePartner {
  display: flex;
  gap: 20px;
  width: 100%;
}

.containerSumary {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.containerTransactions {
  background: var(--color-white);
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

.containerTransactions .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 20px;
}

.cardFinancePartner {
  flex: 1;
  padding: 20px;
}

.cardFinancePartner .title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 15px;
}

.cardFinancePartner .containerText {
  margin-bottom: 10px;
  color: var(--color-text-secondary);
}

.cardFinancePartner .containerText strong {
  margin-right: 5px;
  color: var(--color-text);
}

.cardScore {
  flex: 1;
  padding: 20px;
}

.cardScore .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 10px;
}

.cardScore .subtitle {
  color: var(--color-text-secondary);
  margin-bottom: 15px;
}

.containerBox {
  padding: 20px;
  margin-bottom: 20px;
}

.containerBox .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text);
  margin-bottom: 20px;
}

.containerBox .title img {
  width: 24px;
  height: 24px;
}

.containerCredCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.cardCredit {
  padding: 15px;
  background: var(--color-background);
  border-radius: 8px;
}

.cardCredit .text {
  display: block;
  font-size: 14px;
  color: var(--color-text);
  margin-bottom: 5px;
}

.cardCredit .subtitle {
  font-size: 14px;
  color: var(--color-text-secondary);
}

.buttonNextCRedCard {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--color-primary);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
}

.buttonNextCRedCard:hover {
  background: var(--color-primary-dark);
}

.imgBUtonNex {
  width: 12px;
  height: 12px;
}
