.form-select-container {
    width: 100%;
    position: relative;
    margin-bottom: 15px;
  }
  
  .form-select-label-container {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  
  .form-select-info-icon {
    margin-left: 4px;
    display: flex;
    align-items: center;
    cursor: help;
  }
  
  .form-select-error {
    margin-top: 4px;
  }
  
  .form-select-helper {
    margin-top: 4px;
  }
  
  /* Para manter consistência com o FormInput */
  .form-select {
    width: 100%;
  }

  .form-select-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 100%;
    background-color: #fafafa;
  }
  
  .form-select-label {
    font-size: 14px;
    color: #888;
    margin-right: 5px;
    white-space: nowrap;
    padding: 0px 8px;
  }
  