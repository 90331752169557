.containerStep7 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  gap: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep7:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerStep7 .containerInputs {
  display: flex;
  flex: 1;
  gap: var(--spacing-lg);
  position: relative;
}

.containerStep7 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-bg);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerStep7 .containerInputs .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.custom-timepicker .MuiOutlinedInput-root {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--spacing-md);
  font-size: var(--font-size-title);
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.custom-timepicker .MuiOutlinedInput-root:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.custom-timepicker .MuiOutlinedInput-root.Mui-focused {
  border-color: var(--color-success);
  box-shadow: var(--box-shadow-hover);
}

.custom-timepicker .MuiOutlinedInput-notchedOutline {
  border: none;
}

@media only screen and (max-width: 1153px) {
  .containerStep7 {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .containerStep7 .containerInputs {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .containerStep7 .containerInputs .card {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .custom-timepicker .MuiOutlinedInput-root {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }
}
