@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-family);
}

/* Base Styles */
.containerRegister {
    background-color: var(--color-bg);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.headerRegister,
.footerRegister {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-index-header);
}

.headerRegister {
    top: 0;
    height: var(--header-height);
    background-color: var(--color-white);
    color: var(--color-primary);
}

.footerRegister {
    bottom: 0;
    height: 50px;
    background-color: var(--color-primary);
    color: var(--color-white);
    font-size: var(--font-size-text);
}

.headerRegister img {
    width: 150px;
    transition: var(--transition-base);
}

.headerRegister img:hover {
    transform: scale(1.05);
}

.containerCenterRegister {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: calc(var(--header-height) + var(--spacing-md)) 0;
    padding: var(--spacing-lg);
    position: relative;
    z-index: var(--z-index-main);
    width: 100%;
}

.boxRegister {
    max-width: 663px;
    width: 100%;
    padding: var(--spacing-lg);
    border-radius: var(--card-border-radius);
    background-color: var(--color-white);
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.boxRegister:hover {
    box-shadow: var(--box-shadow-hover);
}

.headerBoxRegister {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    margin-bottom: var(--spacing-lg);
}

.headerBoxRegister .text {
    display: flex;
    align-items: center;
    gap: var(--card-gap);
    font-size: var(--font-size-large-text);
    font-weight: 700;
    color: var(--color-primary);
    line-height: var(--line-height);
}

.headerBoxRegister .subText {
    font-size: var(--font-size-lg);
    font-weight: 400;
    color: var(--color-primary);
    line-height: var(--line-height);
}

.formRegister {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    margin-bottom: var(--spacing-lg);
}

.labelInputRegister {
    font-size: var(--font-size-text);
    font-weight: 700;
    color: var(--color-primary);
    margin-bottom: var(--spacing-sm);
    line-height: var(--line-height);
}

.inputRegister {
    width: 100%;
    height: var(--clients-input-height);
    padding: var(--card-padding);
    font-size: var(--font-size-text);
    background-color: var(--color-bg);
    border: 2px solid transparent;
    border-radius: var(--card-border-radius);
    transition: var(--transition-base);
    font-family: var(--font-family);
    color: var(--color-primary);
}

.inputRegister:focus {
    outline: none;
    background-color: var(--bg-light);
    border-color: var(--color-success);
    box-shadow: var(--box-shadow);
}

.errorText {
    font-size: var(--font-size-base);
    color: var(--color-error);
    margin-top: var(--spacing-sm);
    align-self: flex-end;
}

.buttonRegister {
    align-self: flex-end;
    margin-top: var(--spacing-lg);
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-title);
    font-weight: 700;
    color: var(--color-primary);
    background-color: var(--color-success);
    border: none;
    border-radius: var(--card-border-radius);
    cursor: pointer;
    transition: var(--transition-base);
}

.buttonRegister:hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-md);
}

.image1,
.image2 {
    position: absolute;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    z-index: var(--z-index-main);
    transition: var(--transition-base);
}

.image1 {
    top: 0;
    left: 0;
}

.image2 {
    bottom: 0;
    right: 0;
}

/* Responsive Adjustments */
@media (max-height: 800px), (max-width: 570px) {
    .containerCenterRegister {
        margin: calc(var(--header-height) - var(--spacing-md)) 0;
    }
    
    .boxRegister {
        padding: var(--spacing-md);
        border-radius: var(--border-radius);
    }
    
    .headerBoxRegister .text {
        font-size: var(--font-size-title);
    }
    
    .headerBoxRegister .subText,
    .labelInputRegister {
        font-size: var(--font-size-base);
        margin-bottom: var(--spacing-sm);
    }
    
    .inputRegister {
        height: var(--card-height-mobile);
        font-size: var(--font-size-base);
        border-radius: var(--border-radius);
    }
    
    .inputGroup {
        margin-bottom: var(--spacing-md);
    }
    
    .buttonRegister {
        font-size: var(--font-size-lg);
        margin-top: var(--spacing-lg);
    }
    
    .footerRegister {
        height: 40px;
        font-size: var(--font-size-base);
    }
    
    .headerRegister {
        height: var(--header-height);
    }
    
    .headerRegister img {
        width: 120px;
    }
}

@media (max-width: 570px) {
    .boxRegister {
        padding: var(--spacing-md);
    }
    
    .headerBoxRegister .text {
        font-size: var(--font-size-lg);
    }
    
    .inputRegister {
        height: var(--card-height-mobile);
    }
    
    .buttonRegister {
        font-size: var(--font-size-text);
    }
    
    .footerRegister {
        font-size: var(--font-size-base);
    }
    
    .headerRegister {
        height: var(--header-height);
    }
}

@media (min-width: 1920px) {
    .containerCenterRegister {
        margin: calc(var(--header-height) + var(--spacing-lg)) 0;
    }
    
    .boxRegister {
        padding: calc(var(--spacing-lg) * 2);
    }
    
    .headerBoxRegister .text {
        font-size: var(--font-size-large-text);
    }
    
    .headerBoxRegister .subText {
        font-size: var(--font-size-lg);
    }
    
    .labelInputRegister {
        font-size: var(--font-size-lg);
        margin-bottom: var(--spacing-md);
    }
    
    .inputRegister {
        height: calc(var(--clients-input-height) * 1.5);
        font-size: var(--font-size-lg);
    }
    
    .inputGroup {
        margin-bottom: var(--spacing-lg);
    }
    
    .buttonRegister {
        font-size: var(--font-size-title);
        margin-top: var(--spacing-lg);
    }
    
    .footerRegister {
        height: 60px;
        font-size: var(--font-size-lg);
    }
    
    .headerRegister {
        height: calc(var(--header-height) * 1.2);
    }
}
