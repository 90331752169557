.containerAccountLinksSection {
  padding: 24px;
  background: linear-gradient(180deg, #f8f9fc 0%, #ffffff 100%);
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

.titlePage {
  font-size: 24px;
  font-weight: 600;
  color: #102649;
  margin-bottom: 24px;
}

.containerInput {
  background: #ffffff;
  border-radius: 16px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #102649;
  margin-bottom: 8px;
}

.subTitleInput {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 20px;
}

.containerButtonsContas {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
}

.containerNumber {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background: #f3f4f6;
  border-radius: 12px;
  font-size: 16px;
  color: #102649;
  position: relative;
  min-width: 200px;
}

.containervinculo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  background: #f3f4f6;
  border-radius: 12px;
  font-size: 16px;
  color: #102649;
  position: relative;
  min-width: 200px;
}

.statusIndicator {
  position: absolute;
  right: -6px;
  top: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.statusIndicator.connected {
  background-color: #77E191;
}

.statusIndicator.disconnected {
  background-color: #e6e9ed;
}

.statusText {
  font-size: 12px;
  margin-left: auto;
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: 500;
}

.statusText.connected {
  color: #77E191;
  background-color: rgba(119, 225, 145, 0.1);
}

.statusText.disconnected {
  color: #6b7280;
  background-color: rgba(107, 114, 128, 0.1);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 200px;
}

.button button {
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  color: inherit;
  cursor: pointer;
}

.button:hover {
  opacity: 0.9;
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.buttonSimulator {
  padding: 12px 24px;
  border-radius: 12px;
  background: #f3f4f6;
  color: #102649;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.buttonSave {
  padding: 12px 24px;
  border-radius: 12px;
  background: #77E191;
  color: #102649;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.buttonSimulator:hover,
.buttonSave:hover {
  opacity: 0.9;
}

.disabledMenus {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .containerAccountLinksSection {
    padding: 16px;
  }

  .containerButtonsContas {
    flex-direction: column;
    align-items: stretch;
  }

  .containerNumber,
  .containervinculo,
  .button {
    width: 100%;
  }

  .containerButtons {
    flex-direction: column;
  }

  .buttonSimulator,
  .buttonSave {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
