/* Container */
.schedule-config-container {
  padding: var(--spacing-lg);
  background-color: var(--color-bg);
  min-height: calc(100vh - var(--header-height));
}

.schedule-config-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.schedule-config-content {
  padding: 1.5rem;
}

/* Header */
.schedule-config-header {
  margin-bottom: 2rem;
}

.schedule-config-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.schedule-config-subtitle {
  color: rgb(107, 114, 128);
}

/* Sections */
.schedule-config-sections {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem!important;
}

@media (min-width: 1024px) {
  .schedule-config-sections {
    grid-template-columns: repeat(2, 50%);
    grid-template-areas:
      "weekdays weekdays"
      "hours meeting"
      "dnd dnd";
  }

  .schedule-config-sections > :nth-child(1) {
    grid-area: weekdays;
  }

  .schedule-config-sections > :nth-child(2) {
    grid-area: hours;
  }

  .schedule-config-sections > :nth-child(3) {
    grid-area: meeting;
  }

  .schedule-config-sections > :nth-child(4) {
    grid-area: dnd;
  }
}

/* Section Common */
.schedule-config-section {
  display: flex;
  flex-direction: column;
  width: fill;
  gap: 1rem;
  background-color: var(--color-bg);
  padding: 1.5rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  border: 1px solid var(--color-line);
}

.schedule-config-section-header {
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
}

.schedule-config-section-icon {
  background-color: rgb(243, 244, 246);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.schedule-config-section-title {
  font-weight: 500;
}

.schedule-config-section-description {
  font-size: 0.875rem;
  color: rgb(107, 114, 128);
}

/* Icons */
.schedule-config-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(75, 85, 99);
}

/* Week Days */
.schedule-config-weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
}

.schedule-config-weekday-button {
  padding: 0.5rem 0.25rem;
  font-size: 0.875rem;
  border-radius: 0.5rem;
  transition: all 150ms;
  border: 1px solid transparent;
}

.schedule-config-weekday-button:not(.schedule-config-weekday-selected):hover {
  border-color: rgb(191, 219, 254);
}

.schedule-config-weekday-selected {
  background-color: rgb(59, 130, 246);
  color: white;
}

.schedule-config-weekday-unselected {
  background-color: white;
  border-color: rgb(229, 231, 235);
  color: rgb(55, 65, 81);
}

/* Form Elements */
.schedule-config-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.schedule-config-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

/* Grid Utilities */
.schedule-config-grid-cols-2 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 640px) {
  .schedule-config-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* Time inputs */
.schedule-config-time-inputs {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.schedule-config-input,
.schedule-config-time-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: #374151;
  appearance: none;
  background-color: white;
  height: 38px;
}

.schedule-config-input:focus,
.schedule-config-time-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.schedule-config-input[type="time"],
.schedule-config-time-input[type="time"] {
  min-width: 120px;
}

.schedule-config-input-with-unit {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.schedule-config-input-unit {
  color: #374151;
  font-size: 0.875rem;
  white-space: nowrap;
}

.schedule-config-time-separator {
  color: var(--accents-5);
  font-weight: 500;
}

/* Error Message */
.schedule-config-error {
  color: rgb(220, 38, 38);
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Footer */
.schedule-config-footer {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgb(229, 231, 235);
}

.schedule-config-button {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  color: white;
  background-color: rgb(59, 130, 246);
  transition: background-color 150ms;
}

.schedule-config-button:hover:not(:disabled) {
  background-color: rgb(37, 99, 235);
}

.schedule-config-button:disabled {
  background-color: rgb(156, 163, 175);
  cursor: not-allowed;
}

.schedule-config-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.schedule-config-loading-spinner {
  width: 1rem;
  height: 1rem;
  border: 2px solid white;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
