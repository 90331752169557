/* Container principal */
.message-broadcast-container {
  padding: var(--spacing-lg);
  background-color: var(--color-bg);
  min-height: calc(100vh - var(--header-height));
}

/* Card principal */
.message-broadcast-card {
  background-color: var(--color-white);
  border-radius: var(--card-border-radius);
  width: 100%;
}

.message-broadcast-card-content {
  padding: var(--spacing-lg);
  width: 100%;
}

/* Responsividade */
@media (max-width: 768px) {
  .message-broadcast-container {
    padding: var(--spacing-md);
  }
}

.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: var(--spacing-xl);
}

.empty-state {
  width: 100%;
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--accents-5);
}

.action-buttons {
  display: flex;
  gap: var(--spacing-sm);
  justify-content: center;
  align-items: center;
  height: 100%;
}

.action-buttons .geist-table-action-icon {
  cursor: pointer;
  transition: opacity 0.2s;
}

.action-buttons .geist-table-action-icon:hover {
  opacity: 0.7;
}

/* GeistTable customizations */
.geist-table {
  width: 100%;
  border-radius: var(--radius);
  overflow: hidden;
}

.geist-table th {
  background-color: var(--accents-1);
  font-weight: 500;
}

.geist-table td {
  padding: var(--spacing-sm) var(--spacing-md);
}

.geist-table tr:nth-child(even) {
  background-color: var(--accents-1);
}

.geist-table tr:hover {
  background-color: var(--accents-2);
}
