@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerChatHistorySales {
  display: flex;
  gap: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  padding: 0;
  flex: 1;
  min-height: calc(100vh - var(--header-height));
}

.containerChatHistorySales .headerChatHistory {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  max-width: var(--sidebar-width);
}

.containerChatHistorySales .cardChatHistory {
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-text);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerChatHistorySales .cardChatHistory:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
}

.containerChatHistorySales .containerText {
  display: flex;
  flex-wrap: nowrap;
  line-height: var(--line-height);
}

.containerChatHistorySales .containerText strong {
  flex-shrink: 0;
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
  font-weight: 700;
}

.containerChatHistorySales .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-secondary);
}

.containerChatHistorySales .cardChatHistory .title {
  font-weight: 700;
  font-size: var(--font-size-lg);
  align-self: center;
  margin-bottom: var(--card-gap);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerChatHistorySales .cardChatHistory .containerImg {
  position: absolute;
  top: calc(-2 * var(--clients-card-height));
  left: 50%;
  transform: translateX(-50%);
  width: calc(2 * var(--clients-card-height));
  height: calc(2 * var(--clients-card-height));
  border-radius: var(--border-radius-circle);
  overflow: hidden;
  border: var(--spacing-sm) solid var(--color-white);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerChatHistorySales .cardChatHistory .containerImg:hover {
  transform: translateX(-50%) scale(1.05);
  box-shadow: var(--box-shadow-hover);
}

.containerChatHistorySales .cardChatHistory .containerImg .iconUser {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-base);
}

.containerChatHistorySales .cardScore {
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  display: flex;
  gap: var(--card-gap);
  align-items: center;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerChatHistorySales .cardScore:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
  background-color: var(--bg-light);
}

.containerChatHistorySales .cardScore img {
  width: var(--clients-input-height);
  height: var(--clients-input-height);
  transition: var(--transition-base);
}

.containerChatHistorySales .cardScore:hover img {
  transform: scale(1.1);
}

.containerChatHistorySales .cardScore .containerText {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.containerChatHistorySales .cardScore .containerText .title {
  font-weight: 400;
  font-size: var(--font-size-title);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerChatHistorySales .cardScore .containerText .subtitle {
  font-weight: 700;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
}

.containerSumarySales {
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  flex: 3;
}

.containerSumarySales .containerBox {
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerSumarySales .containerBox:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
  background-color: var(--bg-light);
}

.containerSumarySales .containerBox .title {
  display: flex;
  align-items: center;
  gap: var(--card-gap);
  font-weight: 700;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerSumarySales .containerBox img {
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerSumarySales .containerBox:hover img {
  transform: scale(1.1);
}

.containerSumarySales .containerBox .text {
  font-weight: 400;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerSumarySales .containerBox .containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
}

.containerSumarySales .containerBox .containerHeader .containerIcons {
  display: flex;
  gap: var(--card-gap);
}

.containerSumarySales .containerBox .containerHeader .containerIconFilter img {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerSumarySales .containerBox .containerHeader .containerIconFilter img:hover {
  transform: scale(1.1);
}

.containerSumarySales .containerBox .containerHeader .containerIconFilter button {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  background-color: var(--color-error);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--border-radius);
  font-size: var(--font-size-lg);
  transition: var(--transition-base);
}

.containerSumarySales .containerBox .containerHeader .containerIconFilter button:hover {
  opacity: var(--hover-opacity);
  transform: scale(1.1);
  box-shadow: var(--box-shadow-hover);
}

.containerSumarySales .containerBox .inputSearch {
  width: 100%;
  height: var(--clients-input-height);
  font-family: var(--font-family);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
  padding: var(--card-padding);
  border-radius: var(--input-border-radius);
  border: 2px solid transparent;
  background-color: var(--color-bg);
  transition: var(--transition-base);
}

.containerSumarySales .containerBox .inputSearch:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

@media only screen and (max-width: 1920px) {
  .containerChatHistorySales {
    padding: 0;
  }

  .containerChatHistorySales .cardChatHistory {
    padding: var(--card-padding);
    font-size: var(--font-size-lg);
  }

  .containerChatHistorySales .cardChatHistory .title {
    font-size: var(--font-size-title);
  }

  .containerChatHistorySales .cardScore .containerText .title {
    font-size: var(--font-size-lg);
  }

  .containerChatHistorySales .cardScore .containerText .subtitle {
    font-size: var(--font-size-lg);
  }

  .containerSumarySales .containerBox {
    padding: var(--card-padding);
  }

  .containerSumarySales .containerBox .title {
    font-size: var(--font-size-lg);
  }

  .containerSumarySales .containerBox .text {
    font-size: var(--font-size-text);
  }

  .containerSumarySales .containerBox .containerHeader .containerIconFilter button {
    font-size: var(--font-size-text);
  }

  .containerSumarySales .containerBox .inputSearch {
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 1450px) {
  .containerChatHistorySales {
    flex-direction: column;
  }

  .containerChatHistorySales .headerChatHistory {
    max-width: none;
  }
}

@media only screen and (max-width: 1094px) {
  .containerChatHistorySales .cardChatHistory {
    font-size: var(--font-size-text);
  }

  .containerChatHistorySales .cardChatHistory .title {
    font-size: var(--font-size-lg);
  }

  .containerChatHistorySales .cardScore .containerText .title {
    font-size: var(--font-size-text);
  }

  .containerChatHistorySales .cardScore .containerText .subtitle {
    font-size: var(--font-size-text);
  }

  .containerSumarySales .containerBox {
    padding: var(--spacing-md);
  }

  .containerSumarySales .containerBox .title {
    font-size: var(--font-size-lg);
  }

  .containerSumarySales .containerBox .text {
    font-size: var(--font-size-base);
  }

  .containerSumarySales .containerBox .containerHeader .containerIconFilter button {
    font-size: var(--font-size-base);
  }

  .containerSumarySales .containerBox .inputSearch {
    font-size: var(--font-size-base);
    height: var(--card-height-mobile);
  }

  .containerChatHistorySales .cardChatHistory .containerImg {
    width: calc(2 * var(--clients-card-height));
    height: calc(2 * var(--clients-card-height));
    top: calc(-1 * var(--clients-card-height));
  }
}
