/* FormInput.css - Estilos para o componente FormInput usando padrões Geist UI */

.form-input-container {
  margin-bottom: 16px;
  width: 100%;
  position: relative;
}

.form-input {
  width: 100%;
}

.form-input-label-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.form-input-info-icon {
  margin-left: 8px;
  display: flex;
  align-items: center;
  cursor: help;
  color: #666;
}

.form-input-helper {
  margin-top: 4px;
  font-size: 12px;
  color: #666;
}

.form-input-error {
  margin-top: 4px;
  font-size: 12px;
  color: #e00;
}

/* Estilos para inputs desabilitados */
.form-input-container .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Estilos para inputs com erro */
.form-input-container .error {
  border-color: #e00;
}

/* Estilos para inputs obrigatórios */
.form-input-container .required::after {
  content: "*";
  color: #e00;
  margin-left: 2px;
}

/* Estilos para inputs somente leitura */
.form-input-container .readonly {
  background-color: #fafafa;
  cursor: default;
}

/* Estilos para o tema Geist */
.form-input-container input,
.form-input-container textarea,
.form-input-container select {
  font-family: var(--font-sans);
  border-radius: 5px;
  transition: all 0.2s ease;
}

/* Estilos para o label do Geist */
.form-input-container .label {
  font-size: 14px;
  font-weight: normal;
  color: #444;
  margin-bottom: 4px;
}

/* Estilos para o placeholder */
.form-input-container input::placeholder,
.form-input-container textarea::placeholder {
  color: #999;
}

/* Estilos para o foco */
.form-input-container input:focus,
.form-input-container textarea:focus,
.form-input-container select:focus {
  border-color: #0070f3;
  box-shadow: 0 0 0 2px rgba(0, 112, 243, 0.2);
}

/* Estilos para o hover */
.form-input-container input:hover:not(:disabled):not(:focus),
.form-input-container textarea:hover:not(:disabled):not(:focus),
.form-input-container select:hover:not(:disabled):not(:focus) {
  border-color: #888;
}

/* Utilitário para esconder visualmente elementos mantendo acessibilidade */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.inputMask {
  height: 100%;
  width: 100%;
  border: none;
  border-left: 1px solid #ddd;
  border-radius: 0px!important;
  padding: 0px 10px;
}

.inputMask:focus {
  outline: none!important;
  outline: 0!important;
  border: 1px solid #212529 !important;
  border-radius: 0px!important;
}