@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerCheckout {
  display: flex;
  gap: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  padding: var(--spacing-lg);
  margin-top: var(--header-height);
  flex: 1;
}

.containerCheckout .headerCheckout {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  max-width: 400px;
}

.containerCheckout .cardCheckout {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-title);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerCheckout .cardCheckout:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerCheckout .containerText {
  display: flex;
  flex-wrap: nowrap;
  line-height: var(--line-height);
}

.containerCheckout .title {
  font-weight: 700;
  font-size: var(--font-size-large-text);
  align-self: center;
  margin-bottom: var(--spacing-md);
  margin-top: var(--spacing-lg);
  color: var(--color-primary);
}

.containerCheckout .containerSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: var(--spacing-lg);
}

.containerCheckout .containerSlider .cardSlider {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerCheckout .containerSlider .cardSlider:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerCheckout .containerSlider .titleHea {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-size: var(--font-size-large-text);
  font-weight: 700;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerCheckout .containerSlider .subTitle {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.containerCheckout .Checkout-used {
  background-color: var(--color-bg);
  padding: var(--spacing-lg) var(--spacing-md);
  border-radius: var(--card-border-radius);
  margin-bottom: var(--spacing-lg);
  text-align: center;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transition: var(--transition-base);
}

.containerCheckout .Checkout-used:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.containerCheckout .Checkout-used .title {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.containerCheckout .Checkout-used .subtitle {
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
}

.containerCheckout .plans {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.containerCheckout .containerText {
  display: flex;
  gap: var(--spacing-md);
  height: 100%;
  align-items: center;
}

.containerCheckout .text {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
}

.containerCheckout .cicle {
  color: var(--color-success);
  font-weight: 700;
}

.containerCheckout .subText {
  font-size: var(--font-size-title);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  line-height: var(--line-height);
}

.containerCheckout .textFont {
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
}

.containerSlider .buttonSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  font-size: var(--font-size-title);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--spacing-md);
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: var(--spacing-md);
  transition: var(--transition-base);
}

.containerSlider .buttonSlide:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerSlider .containerResume {
  display: flex;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  justify-content: space-between;
}

.containerSlider .credcardsAll {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
}

.containerSlider .containerCredCards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-md);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  cursor: pointer;
  background-color: var(--color-bg);
  width: 45%;
  transition: var(--transition-base);
}

.containerSlider .containerCredCards:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.containerSlider .containerCredCards .cardCredit {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.containerSlider .containerCredCards .cardCredit .text {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
}

.containerSlider .containerCredCards .cardCredit .subtitle {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-secondary);
}

@media only screen and (max-width: 1920px) {
  .containerCheckout {
    gap: var(--spacing-lg);
    padding: var(--spacing-lg);
  }

  .containerCheckout .cardCheckout {
    padding: var(--spacing-lg);
    font-size: var(--font-size-lg);
  }

  .containerCheckout .title {
    font-size: var(--font-size-title);
  }

  .containerCheckout .containerSlider .titleHea {
    font-size: var(--font-size-title);
  }

  .containerCheckout .containerSlider .subTitle {
    font-size: var(--font-size-lg);
  }

  .containerCheckout .Checkout-used .title {
    font-size: var(--font-size-text);
  }

  .containerCheckout .Checkout-used .subtitle {
    font-size: var(--font-size-lg);
  }

  .containerCheckout .Checkout-used img {
    width: var(--clients-icon-btn-size);
  }

  .containerSlider.plans .text {
    font-size: var(--font-size-lg);
  }

  .containerCheckout .subText {
    font-size: var(--font-size-text);
  }

  .containerSlider .buttonSlide {
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-lg);
  }

  .containerSlider .containerCredCards .cardCredit .text {
    font-size: var(--font-size-lg);
  }
  
  .containerSlider .containerCredCards .cardCredit .subtitle {
    font-size: var(--font-size-lg);
  }
}

@media only screen and (max-width: 1094px) {
  .containerCheckout {
    flex-direction: column;
  }

  .containerCheckout .containerSlider .subTitle {
    font-size: var(--font-size-lg);
  }

  .containerSlider .buttonSlide {
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-text);
    align-self: flex-start !important;
  }

  .containerCheckout .containerSlider .titleHea {
    font-size: var(--font-size-lg);
  }

  .containerSlider .containerCredCards .cardCredit .text {
    font-size: var(--font-size-text);
  }
  
  .containerSlider .containerCredCards .cardCredit .subtitle {
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 600px) {
  .containerSlider .containerCredCards {
    width: 100%;
    padding: var(--spacing-md);
  }

  .containerSlider .containerCredCards .cardCredit {
    flex-direction: row;
    gap: var(--spacing-md);
    align-items: center;
  }
}
