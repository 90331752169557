.containerStep6 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  gap: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep6:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerStep6 .containerButtonsContas {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
}

.containerStep6 .containerButtonsContas .containerNumber {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  width: 260px;
  height: var(--clients-input-height);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
  background-color: var(--color-bg);
  padding: 0 var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerStep6 .containerButtonsContas .containerNumber:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.containerStep6 .containerButtonsContas .containervinculo {
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  width: 360px;
  height: var(--clients-input-height);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
  background-color: var(--color-bg);
  padding: 0 var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerStep6 .containerButtonsContas .containervinculo:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.containerStep6 .containerButtonsContas .button {
  display: flex;
  gap: var(--spacing-sm);
  align-items: center;
  justify-content: center;
  width: 335px;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  cursor: pointer;
  background-color: var(--color-success);
  transition: var(--transition-base);
}

.containerStep6 .containerButtonsContas .button:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerStep6 .containerButtonsContas .button button {
  border: none;
  background-color: transparent;
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
  cursor: pointer;
  transition: var(--transition-base);
}

.containerStep6 .containerButtonsContas .button:hover button {
  transform: scale(1.05);
}

@media only screen and (max-width: 1153px) {
  .containerStep6 {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
  
  .containerStep6 .containerButtonsContas {
    flex-direction: column;
    gap: var(--spacing-md);
  }
  
  .containerStep6 .containerButtonsContas .containerNumber {
    width: 100%;
    font-size: var(--font-size-text);
    height: var(--card-height-mobile);
  }
  
  .containerStep6 .containerButtonsContas .containervinculo {
    width: 100%;
    font-size: var(--font-size-text);
    height: var(--card-height-mobile);
  }
  
  .containerStep6 .containerButtonsContas .button {
    width: 100%;
    height: var(--card-height-mobile);
  }
  
  .containerStep6 .containerButtonsContas .button button {
    font-size: var(--font-size-base);
  }
}
