.containerCardLeads {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding-top: 16px;
}

.scoreValue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #eafbe7;
  color: #539d65;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
}

.chatButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background: transparent;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chatButton:hover {
  border-color: #539d65;
  color: #539d65;
  background-color: #eafbe7;
}

@media only screen and (max-width: 1024px) {
  .scoreValue {
    width: 24px;
    height: 24px;
    font-size: 13px;
  }
}
