@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Graph Container */
.leads-graph {
  text-align: center;
  font-family: var(--font-family);
  padding: 20px;
  background: var(--color-white);
  border-radius: 12px;
  border: 1px solid var(--border-color);
  transition: all 0.2s ease;
}

/* Header */
.leads-graph .header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.leads-graph .header .header-with-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
}

.leads-graph .header .text {
  font-family: var(--font-family);
  text-align: left;
  font-size: var(--font-size-base);
  font-weight: 400;
  color: var(--color-primary);
  line-height: 1.5;
}

.leads-graph .header .text strong {
  font-weight: 600;
}

/* Graph Structure */
.graph-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 200px;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  padding: 0 4px;
}

.fill {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
}

.leads {
  background: linear-gradient(180deg, var(--color-success) 0%, #4caf50 100%);
  width: 100%;
  min-width: 30px;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  border-radius: 4px;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  color: white;
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;
}

/* .leads::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.2s ease;
  border-radius: 4px;
}

.leads:hover::after {
  opacity: 1;
}

.leads:hover {
  transform: translateY(-2px);
} */

.label {
  margin-top: 8px;
  color: #666;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 400;
  transition: all 0.2s ease;
}

.bar:hover .label {
  color: var(--color-primary);
  font-weight: 500;
}

/* Zero value bars */
.leads.empty {
  height: 4px !important;
  background: #eaeaea;
  margin-bottom: 0;
}

.leads.empty:hover {
  transform: none;
}

/* Responsive Design */
@media only screen and (max-width: 1920px) {
  .leads-graph .header .text {
    font-size: var(--font-size-base);
  }
  
  .leads {
    min-width: 25px;
    max-width: 35px;
  }
}

@media only screen and (max-width: 883px) {
  .leads-graph {
    padding: 16px;
  }
  
  .leads-graph .header .text {
    font-size: var(--font-size-sm);
  }
  
  .graph-container {
    height: 160px;
    padding: 12px;
  }
  
  .leads {
    min-width: 20px;
    max-width: 30px;
    font-size: 11px;
  }
  
  .label {
    font-size: 11px;
  }
}
