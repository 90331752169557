@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAttendant {
    padding: var(--spacing-lg);
    font-family: var(--font-family);
    color: var(--color-primary);
    min-height: calc(100vh - var(--header-height));
}

.containerInfoSimulator {
    padding: var(--spacing-lg);
    background-color: var(--color-white);
    border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
    gap: var(--card-gap);
    display: flex;
    flex-direction: column;
    color: var(--color-error);
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerInfoSimulator:hover {
    box-shadow: var(--box-shadow-hover);
}

.containerInfoSimulator strong {
    font-size: var(--font-size-lg);
    line-height: var(--line-height);
}

.containerAttendant .titlePage {
    display: none;
}

.containerAttendant .contentMobile {
    display: none;
}

.containerAttendant .containerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.containerAttendant .containerMenu .menu {
    flex: 1;
    text-align: center;
    padding: var(--card-padding);
    font-size: var(--font-size-lg);
    font-weight: 400;
    cursor: pointer;
    height: var(--clients-input-height);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-base);
}

.containerAttendant .containerMenu .menu:hover:not(.disabledMenus) {
    background-color: var(--bg-light);
}

.containerAttendant .disabledMenus {
    color: var(--border-color) !important;
    cursor: not-allowed;
}

.containerAttendant .containerMenu .menuSelected {
    background: var(--color-white);
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
    font-weight: 700;
    box-shadow: var(--box-shadow);
}

.containerAttendant .containerMenu .line {
    width: 2px;
    height: 23px;
    background: var(--border-color);
}

.containerAttendant .containerMenu .none {
    background-color: transparent !important;
}

.containerAttendant .containerInput {
    display: flex;
    flex-direction: column;
    gap: var(--card-gap);
}

.containerAttendant .containerInput .title {
    display: flex;
    align-items: center;
    font-size: var(--font-size-title);
    font-weight: 700;
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerAttendant .containerInput .subTitleInput {
    font-size: var(--font-size-text);
    font-weight: 400;
    color: var(--color-secondary);
    margin-bottom: var(--spacing-md);
    line-height: var(--line-height);
}

.containerAttendant .containerInput .inputStyle {
    width: 100%;
    height: var(--clients-input-height);
    border-radius: var(--card-border-radius);
    background-color: var(--color-bg);
    border: 2px solid transparent;
    padding: var(--card-padding);
    font-size: var(--font-size-title);
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: var(--font-family);
    color: var(--color-primary);
    transition: var(--transition-base);
}

.containerAttendant .containerInput .inputStyle:focus {
    outline: none;
    border-color: var(--color-success);
    box-shadow: var(--box-shadow);
    background-color: var(--bg-light);
}

.containerAttendant .containerButtons {
    display: flex;
    gap: var(--spacing-lg);
    align-self: flex-end;
}

.containerAttendant .containerButtons .buttonSimulator,
.containerAttendant .containerButtons .buttonSave,
.containerAttendant .containerButtons .buttonReset {
    font-size: var(--font-size-title);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--card-border-radius);
    gap: var(--card-gap);
    cursor: pointer;
    width: 240px;
    height: var(--clients-input-height);
    transition: var(--transition-base);
}

.containerAttendant .containerButtons .buttonSimulator {
    border: 2px solid var(--color-success);
    background-color: var(--color-white);
    color: var(--color-primary);
}

.containerAttendant .containerButtons .buttonSimulator:hover {
    background-color: var(--color-success-bg);
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
}

.containerAttendant .containerButtons .buttonSave {
    background-color: var(--color-success);
    color: var(--color-primary);
}

.containerAttendant .containerButtons .buttonSave:hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

.containerAttendant .containerButtons .buttonReset {
    background-color: var(--color-error);
    color: var(--color-white);
}

.containerAttendant .containerButtons .buttonReset:hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

@media only screen and (max-width: 1500px) {
    .containerAttendant {
        padding: var(--spacing-md);
    }

    .containerAttendant .containerMenu .menu {
        font-size: var(--font-size-text);
    }

    .containerAttendant .containerInput .title {
        font-size: var(--font-size-lg);
    }

    .containerAttendant .containerInput .subTitleInput {
        font-size: var(--font-size-base);
    }

    .containerAttendant .containerInput .inputStyle {
        height: var(--card-height-mobile);
        font-size: var(--font-size-lg);
    }

    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: var(--font-size-lg);
        width: 200px;
        height: var(--card-height-mobile);
    }
}

@media only screen and (max-width: 1431px) {
    .containerAttendant .containerMenu .menu {
        font-size: var(--font-size-base);
    }

    .containerAttendant .containerInput .title {
        font-size: var(--font-size-text);
    }

    .containerAttendant .containerInput .subTitleInput {
        font-size: var(--font-size-base);
    }

    .containerAttendant .containerInput .inputStyle {
        height: var(--card-height-mobile);
        font-size: var(--font-size-text);
    }

    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: var(--font-size-text);
        width: 180px;
        height: var(--card-height-mobile);
    }
}

@media only screen and (max-width: 1153px) {
    .containerAttendant .contentWeb {
        display: none;
    }

    .containerAttendant .contentMobile {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-lg);
    }

    .containerAttendant .containerMenu {
        display: none;
    }

    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: var(--font-size-base);
        width: 140px;
        height: var(--card-height-mobile);
    }

    .containerAttendant .containerButtons .buttonSave img {
        width: var(--spacing-md);
    }

    .containerAttendant .titlePage {
        display: block;
        font-size: var(--font-size-title);
        font-weight: 700;
        color: var(--color-primary);
        line-height: var(--line-height);
    }

    .containerAttendant .containerButtons {
        align-self: center;
    }
}
