.month-selector {
  appearance: none;
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  padding: 6px 28px 6px 12px;
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-primary);
  cursor: pointer;
  transition: all 0.2s ease;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.month-selector:hover {
  border-color: var(--color-primary);
}

.month-selector:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
}

.month-selector option {
  padding: 8px;
  font-size: var(--font-size-sm);
}
