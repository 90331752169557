.containerFluxoAdd {
  width: 100%;
  height: 100%;
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 1rem;
}

.card {
  background: white;
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  width: 100%;
}

.card-content {
  padding: var(--spacing-lg);
}
