@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Base Container Styles */
.containerAttendantCRM,
.containerBusinessSettings {
  font-family: var(--font-family);
  color: var(--color-primary);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerAttendantCRM:hover,
.containerBusinessSettings:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerBusinessSettings {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: var(--card-border-radius);
  padding: var(--spacing-lg);
}

/* Menu Styles */
.containerMenuCrm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.containerMenuCrm .menuCrm {
  flex: 1;
  text-align: center;
  padding: var(--spacing-md) var(--spacing-lg);
  font-size: var(--font-size-lg);
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: var(--line-height);
  transition: var(--transition-base);
}

.containerMenuCrm .menuCrm:hover:not(.disabledMenus) {
  background-color: var(--bg-light);
}

.menuSelectedCrm {
  background: var(--color-white);
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  font-weight: 700;
  box-shadow: var(--box-shadow);
}

.containerMenuCrm .line {
  width: 2px;
  height: 23px;
  background: var(--border-color);
}

.disabledMenus {
  color: var(--border-color) !important;
  cursor: not-allowed;
}

/* Input Container Styles */
.containerInput {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.containerInput .title {
  display: flex;
  align-items: center;
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerInput .subTitleInput {
  font-size: var(--font-size-text);
  color: var(--border-color);
  margin-bottom: var(--spacing-md);
}

.containerInput .inputStyle {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--spacing-md);
  font-size: var(--font-size-title);
  font-family: var(--font-family);
  color: var(--color-primary);
  resize: none;
  cursor: pointer;
  transition: var(--transition-base);
}

.containerInput .inputStyle:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

/* Button Styles */
.containerButtons {
  display: flex;
  gap: var(--spacing-lg);
  align-self: flex-end;
}

.buttonSimulator,
.buttonSave,
.buttonReset {
  font-size: var(--font-size-title);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--card-border-radius);
  gap: var(--spacing-md);
  cursor: pointer;
  width: 240px;
  height: var(--clients-input-height);
  transition: var(--transition-base);
}

.buttonSimulator {
  border: 2px solid var(--color-success);
  background-color: var(--color-white);
}

.buttonSimulator:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.buttonSave {
  background-color: var(--color-success);
  border: none;
}

.buttonSave:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.buttonReset {
  background-color: var(--color-error);
  color: var(--color-white);
  border: none;
}

.buttonReset:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

/* Info Container Styles */
.containerInfo {
  padding: var(--spacing-lg);
  background-color: var(--color-bg);
  border: 2px solid var(--border-color);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerInfo:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.containerInfo strong {
  font-weight: 700;
  color: var(--color-primary);
}

/* Modal Styles */
.modalContent {
  background: var(--bg-light);
  border-radius: var(--card-border-radius);
  padding: var(--spacing-lg);
  width: 700px;
  max-width: 90%;
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.modalContent:hover {
  box-shadow: var(--box-shadow-hover);
}

/* Responsive Styles */
@media (max-width: 1500px) {
  .containerAttendantCRM,
  .containerBusinessSettings {
    padding: var(--spacing-lg);
  }

  .containerMenuCrm .menu {
    font-size: var(--font-size-text);
  }

  .containerInput .title {
    font-size: var(--font-size-lg);
  }

  .containerInput .subTitleInput {
    font-size: var(--font-size-base);
  }

  .containerInput .inputStyle {
    height: var(--clients-input-height);
    font-size: var(--font-size-lg);
  }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    font-size: var(--font-size-lg);
    width: 200px;
    height: var(--clients-input-height);
  }
}

@media (max-width: 1153px) {
  .contentWeb {
    display: none;
  }

  .contentMobile {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
  }

  .containerMenuCrm {
    display: none;
  }

  .titlePage {
    display: block;
    font-size: var(--font-size-lg);
    font-weight: 700;
    color: var(--color-primary);
  }

  .containerButtons {
    align-self: center;
  }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    font-size: var(--font-size-sm);
    width: 140px;
    height: var(--card-height-mobile);
  }

  .buttonSave img {
    width: var(--button-icon-width);
  }
}

@media (max-width: 900px) {
  .containerBusinessSettings {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .containerMenuCrm .menu {
    font-size: var(--font-size-text);
    padding: var(--spacing-md);
    height: var(--card-height-mobile);
  }

  .containerInfo {
    font-size: var(--font-size-base);
    padding: var(--spacing-md);
  }

  .subMenuContainer {
    padding-left: var(--spacing-lg);
  }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    width: 160px;
    height: var(--card-height-mobile);
  }
}
