@import "../../../theme/variables.css";

.containerSettings {
  padding: var(--spacing-lg);
  margin: 0 auto;
  min-height: 100vh;
}

.settingsCard {
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
}

.settingsHeader {
  padding: var(--spacing-lg);
  border-bottom: 1px solid var(--border-color);
}

.settingsHeader .title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: var(--font-size-title);
  color: var(--color-primary);
  margin-bottom: 4px;
}

.settingsHeader .subtitle {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: var(--font-size-text);
  color: var(--color-primary);
  opacity: 0.8;
  margin-bottom: var(--spacing-lg);
}

.settingsContent {
  padding: var(--spacing-lg);
}

.settingsSection {
  margin-bottom: var(--spacing-lg);
}

.sectionHeader {
  width: 100%;
  padding: var(--spacing-lg);
  background: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 12px 12px 0 0;
  transition: all 0.2s ease;
  margin-bottom: 0;
}

.sectionContent {
  padding: var(--spacing-lg);
  background: #f8f9fa;
  border: 1px solid var(--border-color);
  border-top: none;
  border-radius: 0 0 12px 12px;
}

.sectionHeader:hover {
  background: var(--bg-light);
}

.sectionHeader .title {
  font-family: var(--font-family);
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--color-primary);
}

.sectionHeader .description {
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-secondary);
  margin-top: 4px;
}

.sectionHeader .arrow {
  color: var(--color-primary);
  font-size: var(--font-size-lg);
}

.formField {
  margin-bottom: var(--spacing-lg);
}

.formField label {
  display: block;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-primary);
  margin-bottom: var(--spacing-sm);
}

.formField .description {
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-secondary);
  margin-bottom: var(--spacing-sm);
}

.formInput,
.formTextarea {
  width: 100%;
  padding: var(--spacing-md);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  background-color: var(--color-white);
  transition: border-color 0.2s ease;
}

.formInput:focus,
.formTextarea:focus {
  outline: none;
  border-color: var(--color-primary);
}

.formInput.border-error,
.formTextarea.border-error {
  border-color: var(--color-error);
}

.formTextarea {
  min-height: 100px;
  resize: vertical;
}

.error {
  color: var(--color-error);
  font-size: var(--font-size-sm);
  margin-top: 4px;
  font-family: var(--font-family);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.button {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: 500;
  padding: 8px var(--spacing-lg);
  border-radius: var(--input-border-radius);
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.buttonPrimary {
  color: var(--color-white);
  background: var(--color-success);
}

.buttonPrimary:hover {
  opacity: var(--hover-opacity);
}

.buttonOutline {
  color: var(--color-primary);
  background: var(--color-white);
  border: 1px solid var(--border-color);
}

.buttonOutline:hover {
  background: var(--bg-light);
}

.dragDropArea {
  border: 2px dashed var(--border-color);
  border-radius: 12px;
  padding: var(--spacing-lg);
  text-align: center;
  background: var(--color-white);
  cursor: pointer;
  transition: all 0.2s ease;
}

.dragDropArea:hover {
  border-color: var(--color-primary);
  background: var(--bg-light);
}

.dragDropArea p {
  color: var(--color-secondary);
  margin: var(--spacing-md) 0;
}

.fileList {
  margin-top: var(--spacing-lg);
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-md);
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  margin-bottom: var(--spacing-sm);
}

.fileItem .fileInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.fileItem .fileIcon {
  width: 24px;
  height: 24px;
  color: var(--color-primary);
}

.fileItem .fileName {
  font-weight: 500;
  color: var(--color-primary);
}

.fileItem .fileSize {
  color: var(--color-secondary);
  font-size: var(--font-size-sm);
}

.fileItem .fileActions {
  display: flex;
  gap: var(--spacing-md);
}

.fileItem .actionButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: var(--spacing-sm);
  color: var(--color-secondary);
  transition: color 0.2s ease;
}

.fileItem .actionButton:hover {
  color: var(--color-primary);
}

.fileItem .actionButton.delete:hover {
  color: var(--color-error);
}

.loading-spinner {
  animation: spin 1s linear infinite;
}

.spinner-path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 60;
  }
  50% {
    stroke-dashoffset: 30;
  }
  100% {
    stroke-dashoffset: 60;
  }
}


@media only screen and (max-width: 883px) {
  .containerSettings {
    padding: var(--spacing-md);
  }

  .settingsHeader,
  .settingsContent {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .settingsHeader .title {
    font-size: var(--font-size-lg);
  }

  .settingsHeader .subtitle {
    font-size: var(--font-size-sm);
  }

  .buttonContainer {
    flex-direction: column;
  }

  .button {
    width: 100%;
  }

  .sectionHeader {
    padding: var(--spacing-md);
  }

  .sectionContent {
    padding: var(--spacing-md);
  }
}

@media only screen and (max-width: 480px) {
  .containerSettings {
    padding: var(--spacing-xs);
  }

  .settingsHeader,
  .settingsContent {
    padding: var(--spacing-md) var(--spacing-xs);
  }

  .settingsHeader .title {
    font-size: var(--font-size-sm);
  }

  .settingsHeader .subtitle {
    font-size: var(--font-size-xs);
  }

   .settingsCard {
    width: 100%;
    max-width: 100%;
  }

  .sectionHeader .title {
     font-size: var(--font-size-sm);
   }

  .formField label {
    font-size: var(--font-size-xs);
  }
}
