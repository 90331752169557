@import "../../../../../theme/variables.css";

.containerIdentitySection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  position: relative;
}

.titlePage {
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 600;
  color: var(--color-primary);
}

.containerInputs {
  display: flex;
  gap: var(--spacing-lg);
}

.leftColumn,
.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.containerInput {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.containerInput .title {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: 500;
  color: var(--color-primary);
}

.containerInput .subTitleInput {
  font-family: var(--font-family);
  font-size: var(--font-size-sm);
  color: var(--color-secondary);
}

.inputStyle {
  width: 100%;
  padding: var(--spacing-md);
  border: 1px solid var(--border-color);
  border-radius: var(--input-border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  background-color: var(--color-white);
  transition: border-color 0.2s ease;
}

.inputStyle:focus {
  outline: none;
  border-color: var(--color-primary);
}

textarea.inputStyle {
  min-height: 200px;
  resize: vertical;
}

.containerButtons {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-md);
  margin-top: var(--spacing-lg);
}

.buttonSimulator,
.buttonSave,
.buttonMOdoWizard {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: 500;
  padding: 8px var(--spacing-lg);
  border-radius: var(--input-border-radius);
  cursor: pointer;
  transition: all 0.2s ease;
}

.buttonSimulator {
  color: var(--color-primary);
  background: var(--color-white);
  border: 1px solid var(--border-color);
}

.buttonSimulator:hover {
  background: var(--bg-light);
}

.buttonSave,
.buttonMOdoWizard {
  color: var(--color-white);
  background: var(--color-success);
}

.buttonSave:hover,
.buttonMOdoWizard:hover {
  opacity: var(--hover-opacity);
}

.disabledMenus {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 883px) {
  .containerInputs {
    flex-direction: column;
  }

  .containerButtons {
    flex-direction: column;
  }

  .buttonSimulator,
  .buttonSave,
  .buttonMOdoWizard {
    width: 100%;
  }
}