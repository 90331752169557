@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerClients {
  padding: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  min-height: calc(100vh - var(--header-height));
}

.containerClients .containerInputsClients {
  min-height: var(--clients-input-height);
  margin-bottom: var(--spacing-lg);
}

.containerClients .containerInputsClients .inputSearch {
  width: 100%;
  height: var(--clients-input-height);
  font-family: var(--font-family);
  color: var(--color-primary);
  font-size: var(--font-size-text);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  border: 2px solid transparent;
  background-color: var(--color-bg);
  transition: var(--transition-base);
}

.containerClients .containerInputsClients .inputSearch:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

.containerClients .cardClients {
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  min-height: 60vh;
  overflow-y: auto;
  padding: var(--spacing-lg) 0;
  margin-bottom: var(--spacing-lg);
  position: relative;
}

.containerClients .cardClients::-webkit-scrollbar {
  width: 8px;
}

.containerClients .cardClients::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: var(--border-radius);
}

.containerClients .cardClients .header,
.containerClients .cardClients .containerItens .client {
  display: flex;
  width: 100%;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerClients .cardClients .containerItens .client:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  background-color: var(--bg-light);
}

.containerClients .cardClients .header div,
.containerClients .cardClients .containerItens .client div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--spacing-md) 0;
  text-align: center;
  flex: none;
  width: 200px;
}

.containerClients .cardClients .containerItens .client div {
  padding: var(--spacing-sm);
  color: var(--color-secondary);
  line-height: var(--line-height);
}

.containerClients .cardClients .header div {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerClients .cardClients .header .containerIconFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--card-gap);
}

.containerClients .cardClients .header .containerIconFilter img {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerClients .cardClients .header .containerIconFilter img:hover {
  transform: scale(1.1);
}

.containerClients .cardClients .header .containerIconFilter button {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  background-color: var(--color-error);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--border-radius);
  font-size: var(--font-size-text);
  transition: var(--transition-base);
}

.containerClients .cardClients .header .containerIconFilter button:hover {
  transform: scale(1.1);
  opacity: var(--hover-opacity);
  box-shadow: var(--box-shadow-hover);
}

.containerClients .cardClients .containerItens .client div {
  font-size: var(--font-size-lg);
  font-weight: 400;
}

.containerClients .cardClients .containerItens .buttonMoreCheck {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-success);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  font-weight: 700;
  cursor: pointer;
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerClients .cardClients .containerItens .buttonMoreCheck:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerPartners .cardClients .containerItens .containerName {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: var(--card-padding);
  transition: var(--transition-base);
}

.containerPartners .cardClients .containerItens .containerName:hover {
  border: 2px solid var(--color-primary);
  border-radius: var(--card-border-radius);
  opacity: var(--hover-opacity);
  padding: calc(var(--card-padding) - 2px);
}

.containerPartners .cardClients .containerItens .containerName .userIMage {
  width: var(--clients-user-image-size);
  height: var(--clients-user-image-size);
  border-radius: var(--border-radius-circle);
  object-fit: cover;
  transition: var(--transition-base);
}

.containerPartners .cardClients .containerItens .containerName .userIMage:hover {
  transform: scale(1.1);
  box-shadow: var(--box-shadow-hover);
}

.containerClients .containerPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-lg);
  font-weight: 400;
  margin-top: var(--spacing-lg);
}

.containerClients .containerPagination .pagination {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  width: 400px;
  justify-content: space-between;
}

.containerClients .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  font-size: var(--font-size-lg);
  font-weight: 700;
  font-family: var(--font-family);
  color: var(--color-primary);
  background-color: var(--color-white);
  border: 2px solid var(--color-success);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerClients .pagination .containerButton:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.containerClients .pagination .containerButton .left {
  transform: rotate(-180deg);
  transition: var(--transition-base);
}

.containerClients .pagination .containerButton:hover .left {
  transform: rotate(-180deg) translateX(var(--spacing-sm));
}

.containerClients .containerPagination .totalPage {
  display: flex;
  gap: var(--card-gap);
  align-items: center;
}

.containerClients .containerPagination .totalPage .text {
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
}

.hover-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-align: start;
  transition: background-color 0.2s ease-in-out;
}

.hover-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

@media only screen and (max-width: 1920px) {
  .containerClients {
    padding: var(--spacing-lg);
  }

  .containerClients .containerInputsClients .inputSearch {
    height: var(--clients-input-height);
    font-size: var(--font-size-text);
  }

  .containerClients .cardClients .header div {
    font-size: var(--font-size-lg);
  }

  .containerClients .cardClients .containerItens .client div {
    font-size: var(--font-size-text);
  }

  .containerClients .cardClients .containerItens .buttonMoreCheck {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }

  .containerClients .containerPagination {
    font-size: var(--font-size-text);
  }

  .containerClients .pagination .containerButton {
    font-size: var(--font-size-text);
  }

  .containerClients .containerPagination .totalPage .text {
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 1437px) {
  .containerClients {
    padding: var(--spacing-sm) var(--spacing-lg);
  }

  .containerClients .cardClients .header div,
  .containerClients .cardClients .containerItens .client div {
    flex: none;
    width: 200px;
  }

  .containerClients .containerInputsClients .inputSearch {
    height: var(--card-height-mobile);
    font-size: var(--font-size-base);
  }

  .containerClients .cardClients .header div {
    font-size: var(--font-size-text);
  }

  .containerClients .cardClients .containerItens .client div {
    font-size: var(--font-size-text);
  }

  .containerClients .cardClients .containerItens .buttonMoreCheck {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 720px) {
  .containerClients {
    padding: var(--spacing-md);
  }

  .containerClients .containerPagination {
    font-size: var(--font-size-base);
    flex-direction: column;
    gap: var(--spacing-lg);
    justify-content: center;
  }

  .containerClients .pagination .containerButton {
    font-size: var(--font-size-base);
  }

  .containerClients .containerPagination .pagination {
    gap: var(--spacing-md);
    width: 90vw;
  }

  .containerClients .containerPagination .totalPage .text {
    font-size: var(--font-size-text);
  }

  .containerClients .containerPagination .totalPage {
    justify-content: flex-end;
  }
}

/* Profile Section */
.containerProfile {
  display: flex;
  gap: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  padding: var(--spacing-lg);
  margin-top: var(--header-height);
  flex: 1;
}

.containerProfile .headerProfile {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  max-width: 350px;
}

.containerProfile .cardProfile {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-title);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerProfile .cardProfile:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerProfile .containerText {
  display: flex;
  flex-wrap: nowrap;
  line-height: var(--line-height);
}

.containerProfile .containerText strong {
  flex-shrink: 0;
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
}

.containerProfile .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-secondary);
}

.containerProfile .cardProfile .title {
  font-weight: 700;
  font-size: var(--font-size-large-text);
  align-self: center;
  margin-bottom: var(--spacing-md);
  margin-top: calc(var(--clients-card-height) + var(--spacing-md));
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerProfile .cardProfile .containerImg {
  position: absolute;
  top: calc(-1 * var(--clients-card-height));
  left: 50%;
  transform: translateX(-50%);
  width: calc(2 * var(--clients-card-height));
  height: calc(2 * var(--clients-card-height));
  border-radius: var(--border-radius-circle);
  overflow: hidden;
  border: var(--spacing-sm) solid var(--color-white);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg:hover {
  transform: translateX(-50%) scale(1.05);
  box-shadow: var(--box-shadow-hover);
}

.containerProfile .cardProfile .containerImg .iconUser {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg .editIcon {
  position: absolute;
  right: var(--spacing-sm);
  bottom: var(--spacing-sm);
  cursor: pointer;
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg .editIcon:hover {
  transform: scale(1.1);
}
