@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerFinancePartner {
  display: flex;
  gap: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  padding: var(--spacing-lg);
  margin-top: var(--header-height);
  flex: 1;
}

.containerFinancePartner .containerCredCards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-md);
}

.containerFinancePartner .containerCredCards .cardCredit {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  transition: var(--transition-base);
}

.containerFinancePartner .containerCredCards .cardCredit:hover {
  transform: translateY(-2px);
}

.containerFinancePartner .containerCredCards .cardCredit .text {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
}

.containerFinancePartner .containerCredCards .cardCredit .subtitle {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-secondary);
}

.containerFinancePartner .headerFinancePartner {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  max-width: 400px;
}

.containerFinancePartner .cardFinancePartner {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-title);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerFinancePartner .cardFinancePartner:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerFinancePartner .containerText {
  display: flex;
  flex-wrap: nowrap;
  line-height: var(--line-height);
}

.containerFinancePartner .containerText strong {
  flex-shrink: 0;
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
}

.containerFinancePartner .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-secondary);
}

.containerFinancePartner .cardFinancePartner .title {
  font-weight: 700;
  font-size: var(--font-size-large-text);
  align-self: center;
  margin-bottom: var(--spacing-md);
  color: var(--color-primary);
}

.containerFinancePartner .cardScore {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerFinancePartner .cardScore:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
}

.containerFinancePartner .cardScore img {
  width: var(--clients-input-height);
  height: var(--clients-input-height);
  transition: var(--transition-base);
}

.containerFinancePartner .cardScore:hover img {
  transform: scale(1.1);
}

.containerFinancePartner .cardScore .containerText {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.containerFinancePartner .cardScore .containerText .title {
  font-weight: 400;
  font-size: var(--font-size-title);
  color: var(--color-primary);
}

.containerFinancePartner .cardScore .containerText .subtitle {
  font-weight: 700;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
}

.containerSumary {
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  flex: 3;
}

.containerSumary .containerBox {
  background-color: var(--color-white);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerSumary .containerBox:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerSumary .containerBox .title {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-weight: 700;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
}

.containerSumary .containerBox img {
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerSumary .containerBox:hover img {
  transform: scale(1.1);
}

.containerSumary .containerBox .text {
  font-weight: 400;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
}

.containerSumary .containerBox .containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
}

.containerSumary .containerBox .containerHeader .containerIcons {
  display: flex;
  gap: var(--spacing-md);
}

.containerSumary .containerBox .containerHeader .containerIconFilter img {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerSumary .containerBox .containerHeader .containerIconFilter img:hover {
  transform: scale(1.1);
}

.containerSumary .containerBox .containerHeader .containerIconFilter button {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  background-color: var(--color-error);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--border-radius);
  font-size: var(--font-size-lg);
  transition: var(--transition-base);
}

.containerSumary .containerBox .containerHeader .containerIconFilter button:hover {
  opacity: var(--hover-opacity);
  transform: scale(1.1);
}

.containerSumary .containerBox .inputSearch {
  width: 100%;
  height: var(--clients-input-height);
  font-family: var(--font-family);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
  border: 2px solid transparent;
  background-color: var(--color-bg);
  transition: var(--transition-base);
}

.containerSumary .containerBox .inputSearch:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

.containerFinancePartner .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  font-size: var(--font-size-title);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--spacing-md);
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: var(--spacing-md);
  transition: var(--transition-base);
}

.containerFinancePartner .buttonNextCRedCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerFinancePartner .buttonNextCRedCard .imgBUtonNex {
  width: var(--spacing-md);
  height: var(--spacing-md);
  transition: var(--transition-base);
}

.containerFinancePartner .buttonNextCRedCard:hover .imgBUtonNex {
  transform: translateX(var(--spacing-sm));
}

.containerFinancePartner .cardScore .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20px;
  justify-content: flex-end;
  margin-top: var(--spacing-md);
}

.containerFinancePartner .cardScore .fill {
  background-color: var(--bg-light);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  height: 100%;
}

.containerFinancePartner .cardScore .leads {
  background-color: var(--color-success);
  display: flex;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  color: var(--color-primary);
  height: 100%;
  transition: width var(--graph-transition-speed) ease;
}

@media only screen and (max-width: 1920px) {
  .containerFinancePartner {
    padding: var(--spacing-lg);
  }

  .containerFinancePartner .cardFinancePartner {
    padding: var(--spacing-lg);
    font-size: var(--font-size-lg);
  }

  .containerFinancePartner .cardFinancePartner .title {
    font-size: var(--font-size-title);
  }

  .containerFinancePartner .cardScore .containerText .title {
    font-size: var(--font-size-lg);
  }

  .containerFinancePartner .containerCredCards .cardCredit .text {
    font-size: var(--font-size-lg);
  }
  
  .containerFinancePartner .containerCredCards .cardCredit .subtitle {
    font-size: var(--font-size-lg);
  }

  .containerFinancePartner .cardScore .containerText .subtitle {
    font-size: var(--font-size-lg);
  }

  .containerSumary .containerBox {
    padding: var(--spacing-lg);
  }

  .containerSumary .containerBox .title {
    font-size: var(--font-size-lg);
  }

  .containerSumary .containerBox .text {
    font-size: var(--font-size-text);
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: var(--font-size-text);
  }

  .containerSumary .containerBox .inputSearch {
    font-size: var(--font-size-text);
  }

  .containerFinancePartner .buttonNextCRedCard {
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-lg);
  }
}

@media only screen and (max-width: 1450px) {
  .containerFinancePartner {
    flex-direction: column;
  }

  .containerFinancePartner .headerFinancePartner {
    max-width: none;
  }
}

@media only screen and (max-width: 1094px) {
  .containerFinancePartner .cardFinancePartner {
    font-size: var(--font-size-text);
  }

  .containerFinancePartner .cardFinancePartner .title {
    font-size: var(--font-size-lg);
  }

  .containerFinancePartner .cardScore .containerText .title {
    font-size: var(--font-size-text);
  }

  .containerFinancePartner .containerCredCards .cardCredit .text {
    font-size: var(--font-size-text);
  }
  
  .containerFinancePartner .containerCredCards .cardCredit .subtitle {
    font-size: var(--font-size-text);
  }

  .containerFinancePartner .cardScore .containerText .subtitle {
    font-size: var(--font-size-text);
  }

  .containerSumary .containerBox {
    padding: var(--spacing-md);
  }

  .containerSumary .containerBox .title {
    font-size: var(--font-size-lg);
  }

  .containerSumary .containerBox .text {
    font-size: var(--font-size-base);
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: var(--font-size-base);
  }

  .containerSumary .containerBox .inputSearch {
    font-size: var(--font-size-base);
  }

  .containerFinancePartner .buttonNextCRedCard {
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-text);
    align-self: flex-start !important;
  }
}

@media only screen and (max-width: 600px) {
  .containerFinancePartner .containerCredCards {
    flex-direction: column;
  }

  .containerFinancePartner .containerCredCards .cardCredit {
    flex-direction: row;
    gap: var(--spacing-md);
    align-items: center;
  }
}
