.geist-table-container {
  width: 100%;
  border: 1px solid var(--color-bg);
  border-radius: var(--card-border-radius);
  background-color: var(--color-white);
  overflow-x: auto;
}

.geist-table :global(.table-wrapper) {
  border: none !important;
}

.geist-table thead th {
  border-radius: var(--card-border-radius)!important;
  border: 1px solid var(--color-line);
}

.geist-table :global(thead th:last-child) {
  text-align: center !important;
}

.geist-table :global(td) {
  padding: 0 var(--spacing-lg) !important;
}

/* Reset all image styles in table */
.geist-table img {
  all: initial;
  display: block;
}

/* Then apply our specific styles */
.geist-table-user-avatar {
  width: 32px !important;
  height: 32px !important;
  max-width: 32px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
}

.geist-table-cell-content {
  display: flex;
  align-items: center;
  min-height: 64px;
  color: var(--color-secondary);
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.geist-table-cell-content.actions {
  justify-content: center;
}

/* Loading state */
.geist-table-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-lg);
  min-height: 200px;
  width: 100%;
  border: 1px solid var(--color-bg);
  border-radius: var(--card-border-radius);
  background-color: var(--color-white);
}

/* Empty state */
.geist-table-empty {
  color: var(--color-primary);
  font-family: var(--font-family);
  text-align: center;
  padding: var(--spacing-lg);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid var(--color-bg);
  border-radius: var(--card-border-radius);
  background-color: var(--color-white);
}

/* User component styling */
.geist-table-user {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  max-width: 100%;
  overflow: hidden;
}

.geist-table-container .geist-table .geist-table-user .geist-table-user-avatar {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
  object-fit: cover !important;
  flex-shrink: 0 !important;
  max-width: 32px !important;
}

.geist-table-user-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
  overflow: hidden;
  flex: 1;
}

.geist-table-user-name {
  color: var(--color-primary);
  font-weight: 500;
  font-size: var(--font-size-text);
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.geist-table-user-email {
  color: var(--color-secondary);
  font-size: var(--font-size-sm);
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

/* Badge styling */
.geist-table-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: var(--font-size-sm);
  font-weight: 400;
  white-space: nowrap;
  height: 24px;
}

.geist-table-badge.default {
  background-color: var(--bg-light);
  color: var(--color-secondary);
}

.geist-table-badge.success {
  background-color: var(--color-success);
  color: var(--color-primary);
}

/* Action icons */
.geist-table-action-icon {
  cursor: pointer;
  color: var(--color-primary);
  transition: var(--transition-base);
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  vertical-align: middle;
}

.geist-table-action-icon:hover {
  color: var(--color-secondary);
  transform: scale(1.1);
}

/* Sort icons */
.geist-table-sort-icon {
  color: var(--color-white);
  opacity: 0.8;
}

/* Hover effect */
.geist-table :global(tbody tr:hover) {
  background-color: var(--bg-light) !important;
}

/* Border styling */
.geist-table tbody tr {
  display: table-row;
  width: 100%;
  box-shadow: inset 0 0 0 1px var(--color-line);
  border-radius: var(--card-border-radius);
}
.geist-table {
  border-spacing: var(--spacing-md)!important;
  border-collapse: separate
}
.geist-table tbody tr td:first-child{
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  margin: var(--font-size-sm) 0;
}
.geist-table tbody tr td:last-child{
  border-radius: 0px 10px 10px 0px;
  overflow: hidden;
  margin: var(--font-size-sm) 0;
}
.geist-table tbody tr td{
  border-bottom: 0px;
}
.geist-table tbody tr:last-child {
  border-bottom: none !important;
}