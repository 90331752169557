/* Container styles */
.support-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Card styles */
.support-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.support-card-content {
  padding: 1.5rem;
}

/* Header */
.support-header {
  text-align: center;
  margin-bottom: 2rem;
}

.support-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 0.5rem;
}

.support-header p {
  color: #6b7280;
  font-size: 0.875rem;
}

/* Form */
.support-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.textarea-container {
  position: relative;
}

.textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  resize: vertical;
  font-size: 0.875rem;
  min-height: 200px;
}

.textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.char-counter {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: #6b7280;
}

/* Error messages */
.error-text {
  color: #dc2626;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.button-next {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.button-next:hover {
  background-color: #2563eb;
}

.button-next:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

/* Responsiveness */
@media (max-width: 768px) {
  .support-container {
    padding: 1rem;
  }

  .support-card-content {
    padding: 1rem;
  }
}
