.year-selector {
  display: flex;
  align-items: center;
  gap: 8px;
}

.year-selector .select {
  padding: 6px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: var(--color-white);
  color: var(--color-primary);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  cursor: pointer;
  outline: none;
  min-width: 100px;
}

.year-selector .select:hover {
  border-color: var(--color-primary);
}

.year-selector .select:focus {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(var(--color-primary-rgb), 0.1);
}

@media only screen and (max-width: 883px) {
  .year-selector .select {
    font-size: var(--font-size-sm);
    padding: 4px 8px;
  }
}
