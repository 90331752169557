@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerSuporte {
  width: 60vw;
  padding: var(--spacing-lg) calc(var(--spacing-lg) + var(--spacing-md));
  background-color: var(--color-white);
  margin: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-family: var(--font-family);
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  z-index: var(--z-index-main);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
  min-height: calc(100vh - var(--header-height) - 2 * var(--spacing-lg));
}

.containerSuporte:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerSuporte .title {
  display: flex;
  align-items: center;
  gap: var(--card-gap);
  font-size: var(--font-size-lg);
  font-weight: 700;
  margin-bottom: var(--card-gap);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerSuporte .subTitle {
  font-size: var(--font-size-text);
  font-weight: 400;
  margin-top: var(--spacing-sm);
  color: var(--color-secondary);
  line-height: var(--line-height);
}

.containerSuporte .containerCard {
  display: flex;
  gap: var(--spacing-lg);
  flex: 1;
  margin-top: var(--spacing-lg);
}

.containerSuporte .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-bg);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerSuporte .containerCard .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.containerSuporte .containerCard .card .containerInput .title {
  font-size: var(--font-size-text);
  color: var(--color-primary);
  font-weight: 700;
}

.containerSuporte .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
}

.containerSuporte .containerCard .card .containerInput .inputSuporte {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--card-padding);
  font-size: var(--font-size-text);
  font-weight: 400;
  margin-top: var(--card-gap);
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerSuporte .containerCard .card .containerInput .inputSuporte:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

.containerSuporte .containerCard .line {
  border: 1px solid var(--border-color);
}

.containerSuporte .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.containerSuporte .buttonNextSuporte {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  font-size: var(--font-size-text);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--card-gap);
  align-self: flex-end;
  cursor: pointer;
  border: none;
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerSuporte .buttonNextSuporte:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerSuporte .buttonBackSuporte {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);
  font-size: var(--font-size-text);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--card-gap);
  align-self: flex-end;
  cursor: pointer;
  border: 2px solid var(--color-success);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerSuporte .buttonBackSuporte:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.containerSuporte .buttonBackSuporte img {
  transform: rotate(-180deg);
  transition: var(--transition-base);
}

.containerSuporte .buttonBackSuporte:hover img {
  transform: rotate(-180deg) translateX(var(--spacing-sm));
}

.containerSuporte .containerInput .inputStyle {
  width: 100%;
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--card-padding);
  font-size: var(--font-size-text);
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerSuporte .containerInput .inputStyle:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

.containerImage {
  position: relative;
  display: flex;
}

.containerImage .robo {
  position: absolute;
  right: 0;
  width: 500px;
  object-fit: cover;
  margin-top: 5%;
  transition: var(--transition-base);
}

.containerImage .robo:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 1500px) {
  .containerAttendant .containerInput .inputStyle {
    font-size: var(--font-size-text);
  }
  
  .containerImage .robo {
    display: none;
  }
  
  .containerSuporte {
    flex: 1;
  }
}

@media only screen and (max-width: 1431px) {
  .containerAttendant .containerInput .inputStyle {
    font-size: var(--font-size-base);
  }
}

@media only screen and (max-width: 842px) {
  .containerSuporte {
    padding: var(--spacing-md);
    margin: var(--spacing-md);
  }
  
  .containerSuporte .title {
    font-size: var(--font-size-text);
  }
  
  .containerSuporte .subTitle {
    font-size: var(--font-size-base);
  }
  
  .containerSuporte .containerCard {
    gap: var(--spacing-md);
    flex-direction: column;
    margin-top: var(--card-gap);
  }
  
  .containerSuporte .containerCard .card {
    gap: var(--spacing-md);
  }
  
  .containerSuporte .containerCard .card .containerInput .title {
    font-size: var(--font-size-base);
  }
  
  .containerSuporte .containerCard .card .containerInput {
    gap: var(--card-gap);
  }
  
  .containerSuporte .containerCard .card .containerInput .inputSuporte {
    height: var(--card-height-mobile);
    font-size: var(--font-size-base);
  }
  
  .containerSuporte .containerCard .line {
    display: none;
  }
  
  .containerSuporte .containerButtonsSumary {
    justify-content: flex-end;
  }
  
  .containerSuporte .buttonNextSuporte,
  .containerSuporte .buttonBackSuporte {
    font-size: var(--font-size-base);
    width: 100%;
  }
}
