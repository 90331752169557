.containerStep2Check {
  width: 1320px;
  padding: 20px;
  gap: 34px;
  border-radius: 20px;
  box-shadow: 0px 2px 20px 0px #CDDCE433;
  background: #FFFFFF;
}

.containerAllchatCheck {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.containerAllchatCheck .title {
  font-family: Outfit;
  font-size: 28px;
  font-weight: 700;
  line-height: 35.28px;
  text-align: center;
  color: #102649;
  margin-bottom: 15px;
}

@keyframes typing {
  0% {
    content: '';
  }

  20% {
    content: '.';
  }

  40% {
    content: '..';
  }

  60% {
    content: '...';
  }

  80% {
    content: '....';
  }

  100% {
    content: '.....';
  }
}

.typing::after {
  content: '';
  animation: typing 1s steps(6, end) infinite;
  font-size: 24px;
  font-weight: bold;
}

.containerStep2Check .chat-container {
  display: flex;
  font-family: Arial, sans-serif;
  gap: 34px;
  border-radius: 20px;
}



.chat-box {
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  position: relative;
  background-image: url(../../../../../assets/bg-barra.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 55vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.chat-box::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.containerIputMenssage {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.containerIputMenssage .inputMenssage {
  width: 90%;
  height: 60px;
  border-radius: 20px;
  /* box-shadow: 2px 2px 8px 0px #00000026; */
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerIputMenssage .inputMenssage:focus {
  outline: none;
  box-shadow: 2px 2px 8px 0px #00000026;
}

.containerStep2Check .containerIputMenssage button {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: #77E191;
  border: none;
  cursor: pointer;
}

.containerGroupMensagens {
  display: flex;
  flex-direction: column;
  height: 43vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.containerGroupMensagens::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.containerGroupMensagens .containerAllBoxMessages {
  overflow: auto;
  max-height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.containerGroupMensagens .containerAllBoxMessages::-webkit-scrollbar {
  display: none;
}

.containerGroupMensagens .dateHeader {
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #70829E;
  background-color: #D1E8F4;
  height: 29px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 10px;
  padding: 5px 10px;
}

.containerGroupMensagens .containerBoxMenssagens {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 30px;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.received {
  border-radius: 20px;
  box-shadow: 2px 2px 8px 0px #00000026;
  background-color: #FFFFFF;
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  color: #102649;
  min-width: 100px;
  padding: 25px 15px;
  position: relative;
  align-self: flex-start;
  margin-left: 20px;
}

.containerStep2Check .received:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid #FFFFFF;
  border-bottom: 5px solid transparent;
  border-left: 20px solid #FFFFFF;
  border-right: 20px solid#FFFFFF;
  border-bottom-left-radius: 20px;
  left: -20px;
  top: 0px;
}

.containerStep2Check .sent {
  border-radius: 20px 0px 20px 20px;
  box-shadow: 2px 2px 8px 0px #00000026;
  background-color: #E8EACF;
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  color: #102649;
  min-width: 100px;
  padding: 15px 25px;
  margin-right: 20px;
  position: relative;
  align-self: flex-end;
}

.containerStep2Check .sent:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid #E8EACF;
  border-bottom: 5px solid transparent;
  border-left: 20px solid #E8EACF;
  border-right: 20px solid#E8EACF;
  border-bottom-right-radius: 20px;
  right: -20px;
  top: 0px;
}

.time {
  font-size: 12px;
  color: #999;
  align-self: flex-end;
}

.chat-box input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.headerChatCheck {
  width: 480px;
  height: 326px;
  gap: 34px;
  display: flex;
  flex-direction: column;
}

.headerChatCheck .title {
  font-family: Outfit;
  font-size: 19px;
  font-weight: 700;
  line-height: 23.94px;
  text-align: left;
  color: #102649;
}

.headerChatCheck .subtitle {
  font-family: Outfit;
  font-size: 19px;
  font-weight: 400;
  line-height: 23.94px;
  text-align: left;
  color: #102649;
}

.headerChatCheck button {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
  color: #102649;
  border: none;
  cursor: pointer;

}

.headerChatCheck button img {
  width: 25.6px;
  height: 22.75px;

}

.containerStep2Check .headerChatCheck .buttonBackCheck {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background: #ffffff !important;
  border: 2px solid #77E191 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
  color: #102649;
  cursor: pointer;
}

@media only screen and (max-width: 1153px) {
  .containerStep2Check {
    padding: 15px;
  }

  .chat-box {
    width: 100%;
    padding: 10px;
  }

  .chat-container {
    flex-direction: column;
  }

  .chat-container .tokensContainer {
    width: 100%;
    order: 2;
  }

  .tokens-used .title {
    font-size: 17px;
  }

  .tokens-used .subtitle {
    font-size: 20px;
  }

  .tokens-used img {
    width: 40px;
  }


  .plans .text {
    font-size: 18px;
  }

  .plans .subText {
    font-size: 16px;
  }


  .containerIputMenssage {
    gap: 10px;
  }


  .containerIputMenssage .inputMenssage {
    height: 40px;
    font-size: 16px;
  }

  .containerIputMenssage button {
    width: 40px;
    height: 40px;
  }


  .containerGroupMensagens .dateHeader {
    font-size: 14px;
    height: 29px;
    margin: 20px;
  }

  .containerGroupMensagens .containerBoxMenssagens {
    gap: 20px;
  }


  .received {
    font-size: 18px;
    min-width: 100px;
    padding: 20px 10px;
    margin-left: 5px;
  }

  .received:after {
    border-top: 10px solid #FFFFFF;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -5px;
    top: 0px;
  }

  .sent {
    font-size: 18px;
    min-width: 100px;
    padding: 10px 20px;
    margin-right: 5px;
  }

  .sent:after {
    border-top: 10px solid #E8EACF;
    border-bottom: 5px solid transparent;
    border-left: 10px solid #E8EACF;
    border-right: 10px solid transparent;
    right: -10px;
    top: 0px;
  }

  .time {
    font-size: 10px;
    color: #999;
    align-self: flex-end;
  }

}

@media only screen and (max-width: 700px) {
  .containerStep2Check .chat-container {
    padding: 0px 0px;
  }

  .containerStep2Check .containerIputMenssage button {
    width: 40px;
    height: 40px;
  }
}


@media only screen and (max-width: 650px) {
  .containerStep2Check {
    border-radius: 20px !important;
    gap: 20px !important;
  }
}

@media only screen and (max-width: 1337px) {

  .containerStep2Check {
    width: 90vw;
    padding: 20px;
  }
  
  .containerAllchatCheck{
   margin-bottom: 50px;
  }
  
  .containerAllchatCheck .title {
    font-size: 21px;
  }
  
  
  .typing::after {
    font-size: 18px;
  }
  
  .containerIputMenssage {
    gap: 20px;
  }
  
  .containerIputMenssage .inputMenssage {
    width: 90%;
    height: 40px;
    font-size: 16px;
  }
  
  
  .containerStep2Check .containerIputMenssage button {
    width: 40px;
    height: 40px;
    border-radius: 80px;
  }
  
  
  .containerGroupMensagens .containerBoxMenssagens {
    gap: 20px;
  }
  
  .received {
    font-size: 16px;
    padding: 25px 15px;
  }

  
  .containerStep2Check .sent {
    font-size: 16px;
    padding: 15px 25px;
  }
  
  .time {
    font-size: 10px;
  }
  
  
  .headerChatCheck {
    width: 80vw;
    height: 100%;
    gap: 10px;
  }
  
  .headerChatCheck .title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .headerChatCheck .subtitle {
    font-size: 16px;
  }
  
  .headerChatCheck button {
    font-size: 16px;
    height: 40px;
  }
  
  .headerChatCheck button img {
    width: 19px;
    height: 16px;
  }
  
  .containerStep2Check .headerChatCheck .buttonBackCheck {
    font-size: 16px;
    height: 40px;
  }
}