/* Reset completo do componente GeistTableFilter */
.geist-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 0;
}

/* Container do campo de busca */
.geist-table-filter-search {
  position: relative;
  max-width: 320px;
  height: 36px;
}

/* Estilos para o FormInput */
.geist-table-filter-input {
  margin: 0 !important;
  width: 100%;
}

.geist-table-filter-input .form-input {
  height: 36px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  background-color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.geist-table-filter-input input {
  height: 34px;
  border: none;
  outline: none;
  background: none;
  width: 100%;
  color: #000;
  font-size: 14px;
  padding: 0 30px 0 12px;
  box-sizing: border-box;
}

.geist-table-filter-input input::placeholder {
  color: #666;
}


/* Botão de limpar */
.geist-table-filter-clear {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
  background: none;
  border: none;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  opacity: 0.6;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.geist-table-filter-clear:hover {
  opacity: 1;
}

/* Container dos botões */
.geist-table-filter-actions {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 36px;
}

/* Estilos para os botões */
.geist-table-filter-actions .button {
  height: 36px !important;
  padding: 0 16px !important;
  border-radius: 6px !important;
  border: 1px solid #eaeaea !important;
  background-color: white !important;
  color: #000 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.geist-table-filter-actions .button:hover {
  background-color: #f5f5f5 !important;
}

.geist-table-filter-actions .button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
}

/* Esconde o label visualmente mas mantém para acessibilidade */
.geist-table-filter-input .form-input-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Ajustes para o FormInput no tema Geist */
.form-input-search .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.form-input-search .MuiInputBase-root {
  padding-left: 0;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .geist-table-filter {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .geist-table-filter-search {
    max-width: 100%;
    width: 100%;
    margin-bottom: 12px;
  }
  
  .geist-table-filter-actions {
    width: 100%;
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {
  .geist-table-filter-actions {
    flex-wrap: wrap;
    gap: 8px;
  }
  
  .geist-table-filter-actions .button {
    flex: 1;
    min-width: 100px;
  }
}
