/* Estilos para o FormInput na tela de mensagens de broadcast */
.broadcast-form-input {
  margin-bottom: 20px;
}

.broadcast-form-input .form-input {
  background-color: #F3F5F8;
  border-radius: 4px;
  transition: background-color 0.3s, border 0.3s;
}

.broadcast-form-input .form-input:focus-within {
  border: 2px solid #77E191;
}

.broadcast-form-input .form-input-label {
  font-size: 16px;
  font-weight: 600;
  color: #539d65;
  margin-bottom: 5px;
}

.broadcast-form-input .form-input-helper {
  font-size: 14px;
  color: #566986;
}

.broadcast-form-input .form-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Estilos específicos para o campo de telefone */
.phone-input {
  flex: 1;
}

/* Estilos específicos para os campos de parâmetros */
.param-input .form-input-label {
  display: none;
}

.param-input .form-input {
  height: 40px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .broadcast-form-input .form-input-label {
    font-size: 14px;
  }
  
  .broadcast-form-input .form-input-helper {
    font-size: 12px;
  }
}