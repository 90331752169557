
.swiper-button-next, .swiper-button-prev {
    color: #539d65; 
  }

  .swiper-pagination-bullet {
    background-color: #539d65; 
  }
  
  .swiper-pagination-bullet-active {
    background-color: #539d65; 
  }

  .swiperStartAlign {
    align-items: start;
  }