@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import "../../../theme/variables.css";

/* Layout containers */
.containerDashboard {
  padding: var(--spacing-lg);
  display: flex;
  gap: 12px;
}

.containerDashboard .containerLeft,
.containerDashboard .containerRigh {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.containerDashboard .containerRigh {
  flex: 1.5;
}

.containerDashboard .containerLeft {
  flex: 2;
}

.containerDashboard .containerLeft > *:first-child {
  margin-bottom: var(--spacing-sm);
}

.containerDashboard .line {
  height: 100%;
  width: 1px;
  background-color: var(--border-color);
}

/* Header styles */
.containerDashboard .containerHeader {
  display: flex;
  flex-direction: column;
}

.containerDashboard .containerHeader .title,
.containerDashboard .containerHeader .text {
  font-family: var(--font-family);
  text-align: left;
  color: var(--color-primary);
}

.containerDashboard .containerHeader .title {
  font-weight: 700;
  font-size: var(--font-size-title);
  margin-bottom: 4px;
}

.containerDashboard .containerHeader .text {
  font-weight: 400;
  font-size: var(--font-size-text);
  color: var(--color-primary);
  opacity: 0.8;
}

/* Header with selector */
.header-with-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  min-height: 32px;
}

/* Token container */
.containerDashboard .containerTokens {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-md);
  gap: var(--spacing-sm);
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  height: 100%;
  min-height: 100px;
  margin-top: -4px;
}

.containerDashboard .containerTokens .containerHeaderTokens {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-md);
  font-family: var(--font-family);
  font-size: var(--font-size-lg);
  font-weight: 400;
  color: var(--color-primary);
}

.containerDashboard .containerTokens .tokens-count {
  white-space: nowrap;
  font-size: var(--font-size-lg);
}

.containerDashboard .containerTokens .selector-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--spacing-sm);
}

.containerDashboard .containerTokens .bar {
  width: 100%;
  height: 6px;
  background-color: #eaeaea;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
}

.containerDashboard .containerTokens .fill {
  position: relative;
  width: 100%;
  height: 100%;
}

.containerDashboard .containerTokens .leads {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: linear-gradient(90deg, var(--color-success) 0%, #4caf50 100%);
  border-radius: 3px;
  transition: width 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Info cards */
.containerDashboard .containerInfosLeadsSends {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  color: var(--color-primary);
  font-weight: 400;
  font-size: var(--font-size-large-text);
  text-align: center;
  gap: var(--spacing-lg);
}

.containerDashboard .containerInfosLeadsSends .containerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: var(--spacing-lg);
  gap: var(--spacing-md);
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  min-height: 180px;
}

.containerDashboard .containerInfosLeadsSends .containerInfo .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  margin-top: auto;
}

.containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
  font-weight: 600;
  font-size: var(--info-textimg-font-size-desktop);
  gap: var(--spacing-md);
  display: flex;
  align-items: center;
  color: var(--color-primary);
}

/* Alert container */
.containerAlertGoogle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: var(--color-white);
  border: 1px solid var(--color-error);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: 12px;
  gap: var(--spacing-lg);
}

.containerAlertGoogle .containerText {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
}

.containerAlertGoogle .text {
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.containerAlertGoogle .button {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  justify-content: center;
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  font-weight: 500;
  color: #fff;
  background: var(--color-success);
  cursor: pointer;
  padding: 8px var(--spacing-md);
  border-radius: 6px;
  transition: background 0.2s ease;
}

.containerAlertGoogle .button:hover {
  background: #3bab3f;
}

/* Container for leads list */
.containerDashboard .containerRigh {
  background: var(--color-white);
  border-radius: 12px;
  padding: var(--spacing-lg);
}

/* Responsive styles */
@media only screen and (max-width: 1920px) {
  .containerDashboard .containerHeader .title {
    font-size: var(--font-size-title);
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: var(--info-textimg-font-size-mobile);
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerTokens .leads {
    font-size: var(--font-size-sm);
  }

  .containerAlertGoogle .text {
    font-size: var(--font-size-lg);
  }

  .containerAlertGoogle .button {
    font-size: var(--font-size-base);
  }
}

@media only screen and (max-width: 1319px) {
  .containerDashboard {
    flex-direction: column;
  }

  .containerDashboard .line {
    display: none;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard {
    padding: var(--spacing-lg);
    gap: var(--spacing-md);
  }

  .containerDashboard .containerLeft,
  .containerDashboard .containerRigh {
    gap: var(--spacing-md);
  }

  .containerDashboard .containerHeader .title {
    font-size: var(--font-size-lg);
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: var(--font-size-sm);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo img {
    width: var(--clients-icon-btn-size);
  }

  .containerDashboard .containerTokens {
    padding: var(--spacing-lg) var(--spacing-md);
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: var(--font-size-text);
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .containerDashboard .containerTokens .tokens-count {
    font-size: var(--font-size-text);
  }

  .containerDashboard .containerTokens .selector-container {
    width: 100%;
    justify-content: flex-start;
  }

  .containerDashboard .containerTokens .leads {
    font-size: var(--font-size-sm);
  }

  .containerAlertGoogle .text,
  .containerAlertGoogle .button {
    font-size: var(--font-size-base);
  }

  .containerAlertGoogle .button img {
    width: var(--button-icon-width);
  }


  .header-with-selector {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
