/* Container and Card styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.5rem;
}

/* Sections Grid */
.sections-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
  .sections-grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
  }
}

.section {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Section styles */
.section-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #111827;
}

/* Upload section */
.upload-section {
  background-color: #f9fafb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.upload-content {
  max-width: 36rem;
  margin: 0 auto;
  text-align: center;
}

.upload-icon {
  width: 3rem;
  height: 3rem;
  color: #9ca3af;
  margin: 0 auto 1rem;
}

.upload-text h4 {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.upload-text p {
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

/* File preview */
.file-preview {
  background-color: white;
  padding: 1rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.file-icon {
  width: 2rem;
  height: 2rem;
  color: #3b82f6;
}

.file-name {
  font-weight: 500;
}

.file-size {
  font-size: 0.875rem;
  color: #6b7280;
}

/* Divider */
.divider-section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.divider-line {
  flex: 1;
  height: 1px;
  background-color: #e5e7eb;
}

.divider-section span {
  padding: 0 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
  background-color: white;
}

/* Info box */
.info-box {
  background-color: #f9fafb;
  border-radius: 0.375rem;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
}

.info-icon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
  flex-shrink: 0;
  margin-top: 0.125rem;
}

.info-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.params-list {
  margin-top: 0.5rem;
  color: #6b7280;
}

/* Manual input section */
.manual-input-section {
  background-color: #f9fafb;
  border-radius: 0.5rem;
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.input-grid {
  display: grid;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .input-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .input-group.full-width,
  .button.full-width {
    grid-column: 1 / -1;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.button.full-width {
  width: 100%;
  margin-top: 0.5rem;
}

.input-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.phone-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.country-flag {
  width: 2rem;
  height: auto;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

/* Contacts list */
.contacts-list {
  background-color: #f9fafb;
  border-radius: 0.375rem;
  padding: 1rem;
}

.contacts-list h4 {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.contacts-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #e5e7eb;
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contact-flag {
  width: 1.5rem;
  height: 1rem;
}

/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.button-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.button-outline {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
}

.button-outline:hover {
  background-color: #f9fafb;
}

.button-link {
  background: none;
  border: none;
  color: #3b82f6;
  padding: 0;
}

.button-link:hover {
  text-decoration: underline;
}

.button-primary {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.button-primary:hover {
  background-color: #2563eb;
}

.button-primary:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

.button-icon-only {
  padding: 0.5rem;
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  border-radius: 0.375rem;
}

.button-icon-only:hover {
  background-color: #f3f4f6;
}

.icon-small {
  width: 1rem;
  height: 1rem;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.button-back {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
}

.button-back:hover {
  background-color: #f9fafb;
}

.button-next {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.button-next:hover {
  background-color: #2563eb;
}

/* Loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading-content {
  text-align: center;
  color: #6b7280;
}

/* Utility classes */
.hidden {
  display: none;
}
