/* SignUpSales.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-bg);
    min-height: 100vh;
    padding: var(--spacing-lg);
}

.logo {
    width: 200px;
    margin-bottom: var(--spacing-lg);
    transition: var(--transition-base);
}

.logo:hover {
    transform: scale(1.05);
}

.signup-form {
    width: 100%;
    max-width: 400px;
    background-color: var(--color-white);
    padding: var(--spacing-lg);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.signup-form:hover {
    box-shadow: var(--box-shadow-hover);
}

.signup-form form {
    display: flex;
    flex-direction: column;
}

.document-section,
.terms-section {
    margin-bottom: var(--spacing-lg);
}

.document-type {
    display: flex;
    gap: var(--spacing-lg);
    margin-bottom: var(--card-gap);
}

.document-type label {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: var(--font-size-text);
    font-weight: 400;
    line-height: var(--line-height);
    cursor: pointer;
    transition: var(--transition-base);
}

.document-type label:hover {
    color: var(--color-success);
}

.document-type input {
    margin-right: var(--spacing-sm);
    cursor: pointer;
}

.terms-section label {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    font-size: var(--font-size-text);
    font-weight: 400;
    line-height: var(--line-height);
    cursor: pointer;
    transition: var(--transition-base);
}

.terms-section label:hover {
    color: var(--color-success);
}

.terms-section input {
    margin-right: var(--card-gap);
    cursor: pointer;
}

.error-text {
    color: var(--color-error);
    font-size: var(--font-size-base);
    margin-top: var(--spacing-sm);
}

.submit-button {
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-text);
    font-weight: 700;
    color: var(--color-primary);
    background-color: var(--color-success);
    border: none;
    border-radius: var(--card-border-radius);
    cursor: pointer;
    transition: var(--transition-base);
}

.submit-button:not(:disabled):hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

.submit-button:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
}

.documentTypeOptions {
    display: flex;
    gap: var(--spacing-lg);
    margin-top: var(--card-gap);
}

.radioOption {
    display: flex;
    align-items: center;
    gap: var(--spacing-sm);
}

.radioOption label {
    color: var(--color-primary);
    font-size: var(--font-size-text);
    font-weight: 400;
    line-height: var(--line-height);
    cursor: pointer;
    transition: var(--transition-base);
}

.radioOption label:hover {
    color: var(--color-success);
}

.radioOption input {
    cursor: pointer;
}

.buttonRegister:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
}

/* Responsive Adjustments */
@media (max-width: 570px) {
    .signup-container {
        padding: var(--spacing-md);
    }

    .logo {
        width: 150px;
        margin-bottom: var(--spacing-md);
    }

    .signup-form {
        padding: var(--spacing-md);
    }

    .document-type,
    .documentTypeOptions {
        gap: var(--spacing-md);
    }

    .document-type label,
    .terms-section label,
    .radioOption label {
        font-size: var(--font-size-base);
    }

    .submit-button {
        font-size: var(--font-size-base);
        padding: var(--spacing-sm) var(--spacing-md);
    }
}

@media (min-width: 1920px) {
    .signup-container {
        padding: calc(var(--spacing-lg) * 2);
    }

    .logo {
        width: 250px;
        margin-bottom: var(--spacing-lg);
    }

    .signup-form {
        max-width: 500px;
        padding: calc(var(--spacing-lg) * 1.5);
    }

    .document-type label,
    .terms-section label,
    .radioOption label {
        font-size: var(--font-size-lg);
    }

    .submit-button {
        font-size: var(--font-size-lg);
        padding: var(--spacing-lg) calc(var(--spacing-lg) * 2);
    }
}
