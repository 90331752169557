.containerStep5 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep5:hover {
  box-shadow: var(--box-shadow-hover);
}

@keyframes typing {
  0% { content: ''; }
  20% { content: '.'; }
  40% { content: '..'; }
  60% { content: '...'; }
  80% { content: '....'; }
  100% { content: '.....'; }
}

.typing::after {
  content: '';
  animation: typing 1s steps(6, end) infinite;
  font-size: var(--font-size-lg);
  font-weight: bold;
  color: var(--color-primary);
}

.chat-container {
  display: flex;
  padding: var(--spacing-lg);
  font-family: var(--font-family);
  gap: var(--spacing-lg);
}

.chat-container .tokensContainer {
  width: 400px;
}

.tokens-used {
  background-color: var(--color-bg);
  padding: var(--spacing-lg) var(--spacing-md);
  border-radius: var(--card-border-radius);
  margin-bottom: var(--spacing-lg);
  text-align: center;
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  transition: var(--transition-base);
}

.tokens-used:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.tokens-used .title {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
}

.tokens-used .subtitle {
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
}

.plans {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.plans .containerText {
  display: flex;
  gap: var(--spacing-md);
  height: 100%;
  align-items: center;
}

.plans .text {
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
}

.plans .cicle {
  color: var(--color-success);
  font-weight: 700;
}

.plans .subText {
  font-size: var(--font-size-title);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
  margin-bottom: var(--spacing-md);
  line-height: var(--line-height);
}

.chat-box {
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  padding: 0 var(--spacing-lg);
  position: relative;
  background-image: url(../../../../../assets/bg-barra.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 55vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: var(--transition-base);
}

.chat-box:hover {
  box-shadow: var(--box-shadow-hover);
}

.chat-box::-webkit-scrollbar {
  display: none;
}

.containerIputMenssage {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-lg);
}

.containerIputMenssage .inputMenssage {
  width: 90%;
  height: var(--clients-input-height);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 0;
  font-size: var(--font-size-lg);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
  border: 2px solid transparent;
  padding: 0 var(--spacing-lg);
  transition: var(--transition-base);
}

.containerIputMenssage .inputMenssage:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow-hover);
}

.containerIputMenssage button {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius-circle);
  background-color: var(--color-success);
  border: none;
  cursor: pointer;
  transition: var(--transition-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerIputMenssage button:hover {
  transform: scale(1.05);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerGroupMensagens {
  display: flex;
  flex-direction: column;
  height: 43vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: var(--spacing-md);
  padding: var(--spacing-md) 0;
}

.containerGroupMensagens::-webkit-scrollbar {
  display: none;
}

.containerGroupMensagens .dateHeader {
  font-size: var(--font-size-text);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--border-color);
  background-color: var(--color-bg);
  height: 29px;
  margin: var(--spacing-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: var(--card-border-radius);
  padding: var(--spacing-sm) var(--spacing-md);
  transition: var(--transition-base);
}

.containerGroupMensagens .dateHeader:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
}

.containerGroupMensagens .containerBoxMenssagens {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacing-lg);
}

.message {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.received {
  border-radius: 0 var(--card-border-radius) var(--card-border-radius) var(--card-border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-white);
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 400;
  text-align: left;
  color: var(--color-primary);
  min-width: 100px;
  padding: var(--spacing-lg) var(--spacing-md);
  position: relative;
  align-self: flex-start;
  margin-left: var(--spacing-lg);
  transition: var(--transition-base);
}

.received:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
}

.received:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid var(--color-white);
  border-bottom: 2px solid transparent;
  border-left: 5px solid transparent;
  border-right: 20px solid var(--color-white);
  left: -20px;
  top: 0;
}

.sent {
  border-radius: var(--card-border-radius) 0 var(--card-border-radius) var(--card-border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-success-bg);
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 400;
  text-align: left;
  color: var(--color-primary);
  min-width: 100px;
  padding: var(--spacing-md) var(--spacing-lg);
  margin-right: var(--spacing-lg);
  position: relative;
  align-self: flex-end;
  transition: var(--transition-base);
}

.sent:hover {
  box-shadow: var(--box-shadow-hover);
  transform: translateY(-2px);
}

.sent:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid var(--color-success-bg);
  border-bottom: 2px solid transparent;
  border-left: 20px solid var(--color-success-bg);
  border-right: 5px solid transparent;
  right: -20px;
  top: 0;
}

.time {
  font-size: var(--font-size-sm);
  color: var(--border-color);
  align-self: flex-end;
}

.chat-box input {
  width: calc(100% - var(--spacing-lg));
  padding: var(--spacing-md);
  border: 2px solid transparent;
  border-radius: var(--border-radius);
  margin-top: var(--spacing-md);
  transition: var(--transition-base);
}

.chat-box input:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

@media only screen and (max-width: 1153px) {
  .containerStep5 {
    padding: var(--spacing-md);
  }

  .chat-box {
    width: 100%;
    padding: var(--spacing-md);
  }

  .chat-container {
    flex-direction: column;
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .chat-container .tokensContainer {
    width: 100%;
    order: 2;
  }

  .tokens-used .title {
    font-size: var(--font-size-text);
  }

  .tokens-used .subtitle {
    font-size: var(--font-size-lg);
  }

  .tokens-used img {
    width: var(--clients-icon-btn-size);
  }

  .plans .text {
    font-size: var(--font-size-lg);
  }

  .plans .subText {
    font-size: var(--font-size-text);
  }

  .containerIputMenssage {
    gap: var(--spacing-md);
  }

  .containerIputMenssage .inputMenssage {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }

  .containerIputMenssage button {
    width: var(--clients-icon-btn-size);
    height: var(--clients-icon-btn-size);
  }

  .containerGroupMensagens .dateHeader {
    font-size: var(--font-size-base);
    height: var(--clients-icon-btn-size);
    margin: var(--spacing-md);
  }

  .containerGroupMensagens .containerBoxMenssagens {
    gap: var(--spacing-md);
  }

  .received {
    font-size: var(--font-size-lg);
    padding: var(--spacing-md);
    margin-left: var(--spacing-sm);
  }

  .received:after {
    border-top: 10px solid var(--color-white);
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -5px;
    top: 0;
  }

  .sent {
    font-size: var(--font-size-lg);
    padding: var(--spacing-md);
    margin-right: var(--spacing-sm);
  }

  .sent:after {
    border-top: 10px solid var(--color-success-bg);
    border-bottom: 5px solid transparent;
    border-left: 10px solid var(--color-success-bg);
    border-right: 10px solid transparent;
    right: -10px;
    top: 0;
  }

  .time {
    font-size: var(--font-size-sm);
  }
}
