.containerStep2 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  gap: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep2:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerStep2 .containerInputs {
  display: flex;
  flex: 1;
  gap: var(--spacing-lg);
}

.containerStep2 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-bg);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerStep2 .containerInputs .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

@media only screen and (max-width: 1153px) {
  .containerStep2 {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .containerStep2 .containerInputs {
    flex-direction: column;
    gap: var(--spacing-md);
  }

  .containerStep2 .containerInputs .card {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
}
