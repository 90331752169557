@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerProfile {
  display: flex;
  gap: var(--spacing-lg);
  font-family: var(--font-family);
  color: var(--color-primary);
  padding: var(--spacing-lg);
  margin-top: var(--header-height);
  flex: 1;
  min-height: calc(100vh - var(--header-height));
}

/* Rest of the file remains unchanged */
.containerProfile .headerProfile {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  max-width: var(--sidebar-width);
}

.containerProfile .cardProfile {
  background-color: var(--color-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  font-size: var(--font-size-text);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  position: relative;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerProfile .cardProfile:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerProfile .containerText {
  display: flex;
  flex-wrap: nowrap;
  line-height: var(--line-height);
}

.containerProfile .containerText strong {
  flex-shrink: 0;
  margin-right: var(--spacing-sm);
  color: var(--color-primary);
  font-weight: 700;
}

.containerProfile .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-secondary);
}

.containerProfile .cardProfile .title {
  font-weight: 700;
  font-size: var(--font-size-lg);
  align-self: center;
  margin-bottom: var(--spacing-lg);
  margin-top: var(--spacing-lg);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerProfile .cardProfile .containerImg {
  position: absolute;
  top: calc(-2 * var(--clients-card-height));
  left: 50%;
  transform: translateX(-50%);
  width: calc(2 * var(--clients-card-height));
  height: calc(2 * var(--clients-card-height));
  border-radius: var(--border-radius-circle);
  overflow: hidden;
  border: var(--spacing-sm) solid var(--color-white);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg:hover {
  transform: translateX(-50%) scale(1.05);
  box-shadow: var(--box-shadow-hover);
}

.containerProfile .cardProfile .containerImg .iconUser {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg .editIcon {
  position: absolute;
  right: var(--spacing-sm);
  bottom: var(--spacing-sm);
  transition: var(--transition-base);
}

.containerProfile .cardProfile .containerImg .editIcon:hover {
  transform: scale(1.1);
}

.containerInputsProfile {
  flex: 1;
  background-color: var(--color-white);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  padding: var(--card-padding);
  flex: 3;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerInputsProfile:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerInputsProfile .title {
  display: flex;
  align-items: center;
  gap: var(--card-gap);
  font-size: var(--font-size-lg);
  font-weight: 700;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerInputsProfile .subTitleInput {
  font-size: var(--font-size-text);
  font-weight: 400;
  color: var(--color-secondary);
  line-height: var(--line-height);
}

.containerInputsProfile .subTitle {
  font-size: var(--font-size-text);
  font-weight: 400;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerInputsProfile .containerCard {
  display: flex;
  gap: var(--spacing-lg);
  flex: 1;
  margin-top: var(--spacing-lg);
}

.containerInputsProfile .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-bg);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerInputsProfile .containerCard .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.containerInputsProfile .containerCard .card .containerInput .title {
  font-size: var(--font-size-text);
  color: var(--color-primary);
  font-weight: 700;
}

.containerInputsProfile .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  height: var(--clients-input-height);
}

.containerInputsProfile .containerCard .card .containerInput .inputCredCard {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--card-padding);
  font-size: var(--font-size-text);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerInputsProfile .containerCard .card .containerInput .inputCredCard:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

.containerInputsProfile .containerCard .line {
  border: 1px solid var(--border-color);
}

.containerProfile .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.containerProfile .buttonDelete {
  text-decoration: underline;
  font-size: var(--font-size-text);
  font-weight: 400;
  color: var(--color-error);
  cursor: pointer;
  transition: var(--transition-base);
}

.containerProfile .buttonDelete:hover {
  opacity: var(--hover-opacity);
}

.containerProfile .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  font-size: var(--font-size-text);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--card-gap);
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: var(--spacing-md);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerProfile .buttonNextCRedCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerProfile .buttonBackCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);
  font-size: var(--font-size-text);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--card-gap);
  align-self: flex-end;
  cursor: pointer;
  border: 2px solid var(--color-success);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerProfile .buttonBackCRedCard:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.containerProfile .buttonBackCRedCard img {
  transform: rotate(-180deg);
  transition: var(--transition-base);
}

.containerProfile .buttonBackCRedCard:hover img {
  transform: rotate(-180deg) translateX(var(--spacing-sm));
}

.cardProfile label {
  cursor: pointer;
}

.cardProfile input[type="file"] {
  display: none;
}

.label-bordered {
  border: none;
}

.containerProfile .containerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.containerProfile .containerMenu .menu {
  flex: 1;
  text-align: center;
  padding: var(--card-padding);
  font-size: var(--font-size-text);
  font-weight: 400;
  cursor: pointer;
  height: var(--clients-input-height);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
}

.containerProfile .containerMenu .menu:hover:not(.disabledMenus) {
  background-color: var(--bg-light);
}

.containerProfile .disabledMenus {
  color: var(--border-color) !important;
  cursor: not-allowed;
}

.containerProfile .containerMenu .menuSelected {
  background: var(--color-white);
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
  font-weight: 700;
  box-shadow: var(--box-shadow);
}

.containerProfile .containerMenu .line {
  width: 2px;
  height: 23px;
  background: var(--border-color);
}

.containerProfile .containerMenu .none {
  background-color: transparent !important;
}

@media only screen and (max-width: 1920px) {
  .containerProfile {
    gap: var(--spacing-md);
    padding: var(--spacing-md);
  }

  .containerProfile .cardProfile {
    padding: var(--spacing-md);
    font-size: var(--font-size-text);
  }

  .containerProfile .cardProfile .title {
    font-size: var(--font-size-lg);
  }

  .containerInputsProfile .title {
    font-size: var(--font-size-lg);
  }

  .containerInputsProfile .subTitleInput {
    font-size: var(--font-size-text);
  }

  .containerInputsProfile .subTitle {
    font-size: var(--font-size-text);
  }

  .containerInputsProfile .containerCard {
    gap: var(--spacing-md);
  }

  .containerInputsProfile .containerCard .card {
    gap: var(--spacing-md);
  }

  .containerInputsProfile .containerCard .card .containerInput .title {
    font-size: var(--font-size-text);
  }

  .containerInputsProfile .containerCard .card .containerInput .inputCredCard {
    font-size: var(--font-size-text);
  }

  .containerInputsProfile .buttonNextCRedCard,
  .containerProfile .buttonDelete,
  .containerInputsProfile .buttonBackCRedCard {
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 1450px) {
  .containerInputsProfile .containerCard {
    flex-direction: column;
  }

  .containerInputsProfile .containerCard .card .containerInput {
    gap: var(--card-gap);
    height: auto;
  }
}

@media only screen and (max-width: 1094px) {
  .containerProfile {
    flex-direction: column;
  }

  .containerProfile .buttonNextCRedCard {
    align-self: center;
  }

  .containerInputsProfile .subTitle,
  .containerInputsProfile .containerCard .card .containerInput .title,
  .containerInputsProfile .containerCard .card .containerInput .inputCredCard,
  .containerInputsProfile .buttonNextCRedCard,
  .containerProfile .buttonDelete,
  .containerInputsProfile .buttonBackCRedCard {
    font-size: var(--font-size-base);
  }
}

@media only screen and (max-width: 700px) {
  .containerProfile .headerProfile {
    max-width: none;
  }

  .containerInputsProfile .containerCard .line {
    display: none;
  }

  .containerProfile .buttonNextCRedCard {
    align-self: flex-end;
    width: 100%;
  }

  .containerProfile .buttonBackCRedCard {
    width: 100%;
  }
}
