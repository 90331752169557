/* Estilos para o FormInput migrado */
.migrated-form-input {
  margin-bottom: 20px;
}

.migrated-form-input .form-input {
  background-color: #F3F5F8;
  border-radius: 4px;
  transition: background-color 0.3s, border 0.3s;
}

.migrated-form-input .form-input:focus-within {
  border: 2px solid #77E191;
}

.migrated-form-input .form-input-label {
  font-size: 16px;
  font-weight: 600;
  color: #539d65;
  margin-bottom: 5px;
}

.migrated-form-input .form-input-helper {
  font-size: 14px;
  color: #566986;
}

.migrated-form-input .form-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .migrated-form-input .form-input-label {
    font-size: 14px;
  }
  
  .migrated-form-input .form-input-helper {
    font-size: 12px;
  }
}