@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerChatHistory {
    display: flex;
    gap: var(--spacing-lg);
    font-family: var(--font-family);
    color: var(--color-primary);
    padding: var(--spacing-lg);
    flex: 1;
}

.containerChatHistory .headerChatHistory {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    max-width: var(--sidebar-width);
}

.containerChatHistory .cardChatHistory {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    font-size: var(--font-size-title);
    display: flex;
    flex-direction: column;
    gap: var(--card-gap);
    position: relative;
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerChatHistory .cardChatHistory:hover {
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-2px);
}

.containerChatHistory .containerText {
    display: flex;
    flex-wrap: nowrap;
    line-height: var(--line-height);
}

.containerChatHistory .containerText strong {
    flex-shrink: 0;
    margin-right: var(--spacing-sm);
    color: var(--color-primary);
    font-weight: 700;
}

.containerChatHistory .containerText span {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-secondary);
}

.containerChatHistory .cardChatHistory .title {
    font-weight: 700;
    font-size: var(--font-size-large-text);
    align-self: center;
    margin-bottom: var(--card-gap);
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerChatHistory .cardChatHistory .containerImg {
    position: absolute;
    top: calc(-2 * var(--clients-card-height));
    left: 50%;
    transform: translateX(-50%);
    width: calc(2 * var(--clients-card-height));
    height: calc(2 * var(--clients-card-height));
    border-radius: var(--border-radius-circle);
    overflow: hidden;
    border: var(--spacing-sm) solid var(--color-white);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerChatHistory .cardChatHistory .containerImg:hover {
    transform: translateX(-50%) scale(1.05);
    box-shadow: var(--box-shadow-hover);
}

.containerChatHistory .cardChatHistory .containerImg .iconUser {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition-base);
}

.containerChatHistory .cardScore {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    display: flex;
    gap: var(--card-gap);
    align-items: center;
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerChatHistory .cardScore:hover {
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-2px);
    background-color: var(--bg-light);
}

.containerChatHistory .cardScore img {
    width: var(--clients-input-height);
    height: var(--clients-input-height);
    transition: var(--transition-base);
}

.containerChatHistory .cardScore:hover img {
    transform: scale(1.1);
}

.containerChatHistory .cardScore .containerText {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.containerChatHistory .cardScore .containerText .title {
    font-weight: 400;
    font-size: var(--font-size-title);
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerChatHistory .cardScore .containerText .subtitle {
    font-weight: 700;
    font-size: var(--font-size-lg);
    color: var(--color-primary);
}

.containerSumary {
    border-radius: var(--card-border-radius);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
    flex: 3;
}

.containerSumary .containerBox {
    background-color: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    display: flex;
    flex-direction: column;
    gap: var(--card-gap);
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerSumary .containerBox:hover {
    box-shadow: var(--box-shadow-hover);
    transform: translateY(-2px);
    background-color: var(--bg-light);
}

.containerSumary .containerBox .title {
    display: flex;
    align-items: center;
    gap: var(--card-gap);
    font-weight: 700;
    font-size: var(--font-size-lg);
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerSumary .containerBox img {
    width: var(--clients-icon-btn-size);
    height: var(--clients-icon-btn-size);
    transition: var(--transition-base);
}

.containerSumary .containerBox:hover img {
    transform: scale(1.1);
}

.containerSumary .containerBox .text {
    font-weight: 400;
    font-size: var(--font-size-lg);
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerSumary .containerBox .containerHeader2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    margin-bottom: var(--spacing-lg);
}

.containerSumary .containerBox .containerHeader2 .containerIcons {
    display: flex;
    gap: var(--card-gap);
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter img {
    cursor: pointer;
    width: var(--clients-icon-btn-size);
    transition: var(--transition-base);
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter img:hover {
    transform: scale(1.1);
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter button {
    cursor: pointer;
    width: var(--clients-icon-btn-size);
    height: var(--clients-icon-btn-size);
    background-color: var(--color-error);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    font-weight: 700;
    border-radius: var(--border-radius);
    font-size: var(--font-size-lg);
    transition: var(--transition-base);
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter button:hover {
    opacity: var(--hover-opacity);
    transform: scale(1.1);
    box-shadow: var(--box-shadow-hover);
}

.containerSumary .containerBox .inputSearch {
    width: 100%;
    height: var(--clients-input-height);
    font-family: var(--font-family);
    color: var(--color-primary);
    font-size: var(--font-size-lg);
    padding: var(--card-padding);
    border-radius: var(--input-border-radius);
    border: 2px solid transparent;
    background-color: var(--color-bg);
    transition: var(--transition-base);
}

.containerSumary .containerBox .inputSearch:focus {
    outline: none;
    border-color: var(--color-success);
    box-shadow: var(--box-shadow);
    background-color: var(--bg-light);
}

@media only screen and (max-width: 1450px) {
    .containerChatHistory {
        flex-direction: column;
        padding: var(--spacing-lg);
    }

    .containerChatHistory .headerChatHistory {
        max-width: none;
    }
}

@media only screen and (max-width: 1094px) {
    .containerChatHistory {
        flex-direction: column;
        padding: var(--spacing-md);
        font-size: var(--font-size-text);
    }

    .containerChatHistory .cardScore .containerText .title {
        font-size: var(--font-size-lg);
    }

    .containerChatHistory .cardScore .containerText .subtitle {
        font-size: var(--font-size-title);
    }

    .containerSumary .containerBox {
        padding: var(--spacing-md);
    }

    .containerSumary .containerBox .title {
        font-size: var(--font-size-lg);
    }

    .containerSumary .containerBox .text {
        font-size: var(--font-size-text);
    }

    .containerSumary .containerBox .containerHeader2 .containerIconFilter button {
        font-size: var(--font-size-base);
    }

    .containerSumary .containerBox .inputSearch {
        font-size: var(--font-size-base);
        height: var(--card-height-mobile);
    }

    .containerChatHistory .cardChatHistory .containerImg {
        width: calc(2 * var(--clients-card-height));
        height: calc(2 * var(--clients-card-height));
        top: calc(-1 * var(--clients-card-height));
    }

    .containerChatHistory .cardScore img {
        width: var(--clients-input-height);
        height: var(--clients-input-height);
    }

    .containerSumary .containerBox img {
        width: var(--clients-icon-btn-size);
        height: var(--clients-icon-btn-size);
    }
}
