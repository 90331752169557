/* Container and Card styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.5rem;
}

/* Stepper styles */
.stepper-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.progress-line {
  position: absolute;
  left: 0;
  top: 1.25rem;
  width: 100%;
  height: 2px;
  background-color: #e5e7eb;
}

.progress-fill {
  height: 100%;
  background-color: #3b82f6;
  transition: width 0.3s ease;
}

.step-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.step-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 2px solid #d1d5db;
  color: #9ca3af;
  transition: all 0.3s ease;
}

.step-icon.active,
.step-icon.completed {
  background-color: #3b82f6;
  border-color: #3b82f6;
  color: white;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.step-title {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
}

.step-title.active {
  color: #3b82f6;
}

/* Section styles */
.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #111827;
}

.section-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #3b82f6;
}

/* Schedule options */
.schedule-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.schedule-option {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid #e5e7eb;
  text-align: left;
  transition: all 0.2s;
  cursor: pointer;
  background: none;
}

.schedule-option:hover {
  border-color: #d1d5db;
}

.schedule-option.selected {
  border-color: #3b82f6;
  background-color: #eff6ff;
}

.option-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.option-title {
  font-weight: 500;
  color: #111827;
}

.option-description {
  font-size: 0.875rem;
  color: #6b7280;
}

/* DateTime inputs */
.datetime-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

/* Alert box */
.alert-box {
  background-color: #fef3c7;
  border: 1px solid #fcd34d;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  gap: 0.75rem;
}

.alert-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #d97706;
  flex-shrink: 0;
  margin-top: 0.125rem;
}

.alert-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #92400e;
  margin-bottom: 0.25rem;
}

.alert-message {
  font-size: 0.875rem;
  color: #92400e;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.button-back {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
}

.button-back:hover {
  background-color: #f9fafb;
}

.button-next {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.button-next:hover {
  background-color: #2563eb;
}

/* Loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading-content {
  text-align: center;
  color: #6b7280;
}
