:root {
  /* Colors */
  --color-bg: #F3F5F8;
  --color-primary: #102649;
  --color-secondary: #06253F;
  --color-white: #FFFFFF;
  --color-dark: #0A1C2D;
  --color-success: #77E191;
  --color-error: #CE3C3C;
  --color-success-bg: #E2EEE5;
  --color-error-bg: #F4E6E6;
  --color-line: #eaeaea;
  --bg-light: #f9f9f9;

  /* Typography */
  --font-family: "Outfit", sans-serif;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-text: 14px;
  --font-size-lg: 16px;
  --font-size-title: 16px;
  --font-size-large-text: 40px;
  --line-height: 1.5;
  
  /* Spacing */
  --spacing-sm: 5px;
  --spacing-md: 10px;
  --spacing-lg: 20px;
  --padding-horizontal: 15px;
  
  /* Layout */
  --border-radius: 4px;
  --header-height: 70px;
  --sidebar-width: 250px;
  
  /* Z-index */
  --z-index-header: 100;
  --z-index-modal: 1000;
  --z-index-main: 10;
  
  /* Transitions */
  --transition-base: all 0.2s ease;
  --transition-speed: 0.5s;
  
  /* Cards */
  --card-border-radius: 10px;
  --card-padding: var(--spacing-md);
  --card-gap: var(--spacing-md);
  --card-height-mobile: 60px;
  --card-divider-color: #bec6d5;
  
  /* Visual Effects */
  --notification-gradient: linear-gradient(135deg, #ff6a00, #ee0979);
  --box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  --box-shadow-hover: 0 2px 8px rgba(0, 0, 0, 0.05);
  --hover-opacity: 0.8;
  --overlay-color: rgba(16, 38, 73, 0.5);

  /* Graph */
  --graph-height: 350px;
  --bar-width: 50px;
  --graph-transition-speed: 0.3s;
  
  /* Components */
  --button-icon-width: 28px;
  --notification-size: 28px;
  --dot-size: 10px;
  
  /* Partner Screens */
  --checkscreens-margin-bottom: 20px;
  
  /* Form Elements */
  --radio-btn-size: var(--spacing-lg);
  --radio-inner-size: 12px;
  --input-border-radius: 8px;
  
  /* Dashboard */
  --info-textimg-font-size-desktop: 42px;
  --info-textimg-font-size-mobile: 36px;
  
  /* UI Elements */
  --spinner-border-width: 8px;
  --border-color: #E5E5E5;
  --border-radius-circle: 50%;
  
  /* Client Screen */
  --clients-input-height: 45px;
  --clients-card-height: 70px;
  --clients-icon-btn-size: 18px;
  --clients-user-image-size: 30px;
  --clients-button-max-width: 160px;
}
