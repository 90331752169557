@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerStep1Check {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}


.containerStep1Check .containerHeader {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    text-align: center;
    width: 494px;
    margin: 20px 0px;
}


.containerStep1Check .containerCardDescription {
    display: flex;
    flex-direction: column;
    width: 494px;
    padding: 30px;
    gap: 18px;
    border-radius: 20px;
    background: #FFFFFF;

}

.containerStep1Check .containerCardDescription .urlInput {
    width: 100%;
    height: 60px;
    border-radius: 20px;
    background: #F3F5F8;
    border: none;
    cursor: pointer;
    padding: 14px 6px 14px 6px;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    text-align: 102649;
    color: #8291A9;
}


.containerStep1Check .containerCardDescription .descriptionInput {
    width: 100%;
    height: 380px;
    border-radius: 20px;
    background: #F3F5F8;
    border: none;
    cursor: pointer;
    padding: 14px 6px 14px 6px;
    resize: none;
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #102649;
}

.containerStep1Check .containerCardDescription .titleCheck {
    font-family: Outfit;
    font-size: 21px;
    font-weight: 700;
    text-align: left;
    color: #102649;
}

.containerStep1Check .containerCardDescription .subtitleCheck {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #8291A9;
}

.containerStep1Check .containerCardDescription .submitButton {
    width: 100%;
    height: 56px;
    padding: 14px 6px 14px 6px;
    gap: 10px;
    border-radius: 16px;
    background: #77E191;
    font-family: Outfit;
    font-size: 21px;
    font-weight: 700;
    line-height: 26.46px;
    text-align: left;
    color: #102649;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerStep1Check .containerCardDescription .submitButton img {
    width: 10px;
    height: 15px;
}

.containerStep1Check .containerCardDescription .buttonTypeINput {
    font-family: Outfit;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    color: #798DA4;
    text-decoration: underline;
    cursor: pointer;
}

.containerStep1Check .containerCardDescription .optionText {
    font-family: Outfit;
    font-size: 21px;
    font-weight: 400;
    text-align: left;
    margin-top: 5px;
}

.containerLoadingCheck {
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.containerLoadingCheck .loadingDescription {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    color: #000000;
    /* Cor do texto */
    animation: fadeIn 0.5s ease-in-out;
    width: 462px;
    height: 207px;
    padding: 36px;
    gap: 19px;
    border-radius: 20px;
    background: #FFFFFF;
    font-family: Outfit;
    font-size: 21px;
    font-weight: 400;
    line-height: 26.46px;
    text-align: center;


}

/* Animação de giro para o ícone */
.containerLoadingCheck .loadingDescription img {
    width: 90px;
    height: 90px;
    /* animation: pulses 2s linear infinite; */
    animation: pulse 2s ease-in-out infinite;
}

/* Animação para o texto pulsar */
.containerLoadingCheck .loadingDescription div {
    margin-top: 10px;
    animation: pulse 2s ease-in-out infinite;
}

/* Keyframes para o giro do ícone */
@keyframes pulses {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
    }
}

/* Keyframes para o efeito de pulsar do texto */
@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Keyframes para o fadeIn do contêiner */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 573px) {

    .containerStep1Check {
        height: 73vh
    }
 
    .containerStep1Check .containerHeader {
        font-size: 20px;
        width: 80vw;
    }
    
    
    .containerStep1Check .containerCardDescription {
        width: 80vw;
        padding: 20px;
        gap: 14px;
    }
    
    .containerStep1Check .containerCardDescription .urlInput {
        width: 100%;
        height: 40px;
    }
    
    
    .containerStep1Check .containerCardDescription .descriptionInput {
        width: 100%;
        height: 100%;
    }
    
    .containerStep1Check .containerCardDescription .titleCheck {
        font-size: 18px;
    }
    
    .containerStep1Check .containerCardDescription .submitButton {
        width: 100%;
        height: 40px;
        font-size: 17px;
    }
    
    
    .containerStep1Check .containerCardDescription .buttonTypeINput {
        font-size: 16px;
    }
    
    
    .containerLoadingCheck .loadingDescription {
        font-size: 16px;
        width: 80vw;
        height: 207px;
        padding: 20px;
        font-size: 18px;

    }
}