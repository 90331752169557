.plan-action {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
}

.change-plan-button {
  background-color: #f3f3f3;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.change-plan-button:hover {
  background-color: #e5e5e5;
}

.arrow-icon {
  margin-right: 8px;
  font-size: 18px;
}

/* Responsividade */
@media (max-width: 768px) {
  .change-plan-button {
    width: 100%;
    justify-content: center;
  }
}