/* Container */
.reminder-config-container {
  padding: var(--spacing-lg);
  background-color: var(--color-bg);
  min-height: calc(100vh - var(--header-height));
}

.reminder-config-card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.reminder-config-content {
  padding: 1.5rem;
}

/* Header */
.reminder-config-header {
  margin-bottom: 2rem;
}

.reminder-config-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.reminder-config-subtitle {
  color: rgb(107, 114, 128);
}

/* Sections */
.reminder-config-sections {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Status Section */
.reminder-config-status {
  background-color: rgb(240, 253, 244);
  border: 1px solid rgb(187, 247, 208);
  border-radius: 0.5rem;
  padding: 1rem;
}

.reminder-config-status-content {
  display: flex;
  gap: 0.75rem;
}

.reminder-config-status-icon {
  background-color: rgb(220, 252, 231);
  padding: 0.5rem;
  border-radius: 9999px;
}

.reminder-config-status-title {
  font-weight: 500;
  color: rgb(22, 101, 52);
}

.reminder-config-status-text {
  font-size: 0.875rem;
  color: rgb(21, 128, 61);
}

/* Section Common */
.reminder-config-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--accents-1);
  padding: 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid var(--accents-2);
}

.reminder-config-section-header {
  display: flex;
  gap: 0.75rem;
  align-items: flex-start;
}

.reminder-config-section-icon {
  background-color: rgb(243, 244, 246);
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.reminder-config-section-title {
  font-weight: 500;
}

.reminder-config-section-description {
  font-size: 0.875rem;
  color: rgb(107, 114, 128);
}

/* Icons */
.reminder-config-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: rgb(75, 85, 99);
}

.reminder-config-error-icon {
  width: 1rem;
  height: 1rem;
}

/* Input Groups */
.reminder-config-input-group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Time inputs */
.reminder-config-time-inputs {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.reminder-config-input {
  appearance: none;
  background-color: white;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
}

.reminder-config-input:focus {
  outline: none;
  border-color: rgb(59, 130, 246);
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.reminder-config-input[type="number"] {
  width: 8rem;
}

.reminder-config-input[type="time"] {
  width: 8rem;
}

.reminder-config-input-label {
  color: rgb(75, 85, 99);
  white-space: nowrap;
}

.reminder-config-time-separator {
  color: var(--accents-5);
  font-weight: 500;
}

/* Error Message */
.reminder-config-error {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgb(220, 38, 38);
  font-size: 0.875rem;
}

/* Footer */
.reminder-config-footer {
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgb(229, 231, 235);
}

.reminder-config-button {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  color: white;
  background-color: rgb(59, 130, 246);
  transition: background-color 150ms;
}

.reminder-config-button:hover:not(:disabled) {
  background-color: rgb(37, 99, 235);
}

.reminder-config-button-disabled {
  background-color: rgb(156, 163, 175);
  cursor: not-allowed;
}

.reminder-config-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.reminder-config-loading-icon {
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Loading State */
.reminder-config-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}
