@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.swiper-button-next,
.swiper-button-prev {
  color: var(--color-success);
  transition: var(--transition-base);
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.1);
  opacity: var(--hover-opacity);
}

.swiper-pagination-bullet {
  background-color: var(--color-success);
  transition: var(--transition-base);
}

.swiper-pagination-bullet:hover {
  transform: scale(1.2);
}

.swiper-pagination-bullet-active {
  background-color: var(--color-success);
  transform: scale(1.2);
}

.swiperStartAlign {
  align-items: flex-start;
}

.containerCRms {
  padding: var(--spacing-lg) calc(var(--spacing-lg) + 10px);
  background-color: var(--color-white);
  margin: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-family: var(--font-family);
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerCRms:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerCRms .buttonMoreLead {
  border-radius: var(--card-border-radius);
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  cursor: pointer;
  align-self: flex-end;
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
  border: none;
  transition: var(--transition-base);
}

.containerCRms .buttonMoreLead:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.titleCrm {
  font-weight: 700;
  font-size: var(--font-size-title);
  text-align: center;
  width: 100%;
  margin-bottom: var(--spacing-md);
  color: var(--color-primary);
  line-height: var(--line-height);
}

.titleCrm2 {
  font-weight: 500;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  align-self: flex-start;
  margin: var(--spacing-md);
  line-height: var(--line-height);
}

.containerAllINputsCrm {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 0;
}

.containerInputCrm {
  display: flex;
  flex-direction: column;
  height: 140px;
  line-height: var(--line-height);
}

.containerInputCrm .title {
  font-size: var(--font-size-text);
  color: var(--color-primary);
  font-weight: 700;
}

.containerInputCrm .subTitleInput {
  font-size: var(--font-size-base);
  font-weight: 400;
  color: var(--border-color);
}

.containerInputCrm .inputCRm {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-bg);
  border: 2px solid transparent;
  padding: var(--spacing-md);
  font-size: var(--font-size-title);
  font-weight: 400;
  margin-top: var(--spacing-sm);
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerInputCrm .inputCRm:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

.containerButonsCrm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: var(--spacing-lg);
  gap: var(--spacing-md);
}

.containerButonsCrm .buttonSend {
  border-radius: var(--card-border-radius);
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  cursor: pointer;
  align-self: flex-end;
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  font-weight: 700;
  color: var(--color-primary);
  border: none;
  transition: var(--transition-base);
}

.containerButonsCrm .buttonSend:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerButonsCrm .buttonMOre {
  border-radius: var(--card-border-radius);
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);
  cursor: pointer;
  align-self: flex-end;
  font-family: var(--font-family);
  font-size: var(--font-size-text);
  font-weight: 700;
  color: var(--color-primary);
  border: 2px solid var(--color-success);
  transition: var(--transition-base);
}

.containerButonsCrm .buttonMOre:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

@media only screen and (max-width: 700px) {
  .containerCRms {
    padding: var(--spacing-md);
    margin: 0;
    gap: var(--spacing-md);
  }

  .containerCRms .buttonMoreLead {
    font-size: var(--font-size-lg);
    padding: var(--spacing-md);
  }

  .titleCrm {
    font-size: var(--font-size-lg);
  }

  .titleCrm2 {
    font-size: var(--font-size-text);
  }

  .containerInputCrm .inputCRm {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }

  .containerButonsCrm .buttonSend,
  .containerButonsCrm .buttonMOre {
    font-size: var(--font-size-base);
    padding: var(--spacing-md);
    width: 100%;
  }
}
