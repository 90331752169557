/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1.5rem;
}

/* Card styles */
.card {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.card-content {
  padding: 1.5rem;
}

/* Flex container */
.flex-container {
  display: flex;
  gap: 1.5rem;
}

/* Message column */
.message-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.25rem;
}

.input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.textarea-container {
  position: relative;
}

.textarea {
  width: 100%;
  height: 200px;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  resize: none;
  font-size: 0.875rem;
}

.char-counter {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 0.75rem;
  color: #6b7280;
}

/* Parameters column */
.params-column {
  width: 20rem;
}

.params-container {
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 0.5rem;
  position: sticky;
  top: 1rem;
}

.info-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.info-icon {
  width: 1rem;
  height: 1rem;
  color: #3b82f6;
}

.info-text {
  font-size: 0.875rem;
  color: #6b7280;
}

.params-section {
  margin-top: 1rem;
}

.params-title {
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.params-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.param-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: white;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.param-name {
  font-size: 0.875rem;
  font-weight: 500;
}

.param-example {
  font-size: 0.75rem;
  color: #6b7280;
}

.param-button {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  transition: color 0.2s;
}

.param-button:hover {
  color: #3b82f6;
}

.plus-icon {
  width: 1rem;
  height: 1rem;
}

.custom-param-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Navigation buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #e5e7eb;
}

.button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.button-back {
  background-color: white;
  border: 1px solid #d1d5db;
  color: #374151;
}

.button-back:hover {
  background-color: #f9fafb;
}

.button-next {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.button-next:hover {
  background-color: #2563eb;
}

/* Loading state */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.loading-content {
  text-align: center;
  color: #6b7280;
}
