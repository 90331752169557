/* Estilos para o FormInput na tela de perfil */
.profileFormInput {
  margin-bottom: 20px;
}

.profileFormInput .form-input {
  background-color: #F3F5F8;
  border-radius: 4px;
  transition: background-color 0.3s, border 0.3s;
}

.profileFormInput .form-input:focus-within {
  border: 2px solid #77E191;
}

.profileFormInput .form-input-label {
  font-size: 16px;
  font-weight: 600;
  color: #539d65;
  margin-bottom: 5px;
}

.profileFormInput .form-input-helper {
  font-size: 14px;
  color: #566986;
}

.profileFormInput .form-input-error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* Estilos para o modal de alteração de senha */
.passwordModal .profileFormInput {
  margin-bottom: 15px;
}

/* Layout dos cards na página de conta */
.account-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;
}

.account-card {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .profileFormInput .form-input-label {
    font-size: 14px;
  }
  
  .profileFormInput .form-input-helper {
    font-size: 12px;
  }
  
  .account-cards-container {
    flex-direction: column;
  }
  
  .account-card {
    width: 100%;
    max-width: 100%;
  }
}
