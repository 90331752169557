.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px!important;
  font-size: 14px!important;
  font-weight: 400!important;
  border-radius: 8px!important;
  cursor: pointer;
  transition: all 0.3s ease;
  height: fit-content!important;
  width: fit-content!important;
  min-width: 0!important;
}

.button-outline {
  background-color: transparent;
  border: 2px solid var(--color-primary);
  color: var(--color-primary);
}

.button-outline:hover {
  background-color: var(--color-primary);
  color: rgb(97, 97, 97);
}

.button-filled {
  background-color: var(--color-primary);
  border: none;
  color: white;
}

.button-filled:hover {
  opacity: 0.9!important;;
}

.button-with-icon {
  gap: 4px!important;
}

.button-icon {
  display: flex;
  align-items: center;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}