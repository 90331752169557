.mainContainer {
  padding: var(--spacing-lg);
  margin: 0 auto;
}

.mainContainer.flex {
  display: flex;
  flex-direction: column;
}

.mainContainer.minimal {
  padding: 0;
}

.mainCard {
  background: var(--color-white);
  border: 1px solid var(--border-color);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.mainCard.flex {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 1em;
  padding: 1em;
  flex-wrap: wrap;
}

/* Variante Minimalista */
.mainCard.minimal {
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 1em;
}
