.containerCheckProfile {
  padding: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background: var(--color-white);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
}

.containerHeader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
}

.title {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
  font-family: var(--font-family);
  line-height: var(--line-height);
}

.text {
  font-size: var(--font-size-lg);
  font-weight: 400;
  color: var(--color-primary);
  font-family: var(--font-family);
  line-height: var(--line-height);
}

.urlForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.optionSelection {
  display: flex;
  gap: var(--spacing-lg);
  padding: var(--spacing-md) 0;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: var(--font-size-lg);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.custom-radio:hover {
  color: var(--color-success);
}

.custom-radio input {
  display: none;
}

.radio-btn {
  height: var(--radio-btn-size);
  width: var(--radio-btn-size);
  background-color: var(--color-white);
  border: 2px solid var(--color-success);
  border-radius: var(--border-radius-circle);
  margin-right: var(--spacing-md);
  position: relative;
  transition: var(--transition-base);
}

.custom-radio:hover .radio-btn {
  box-shadow: var(--box-shadow);
  transform: scale(1.05);
}

.custom-radio input:checked ~ .radio-btn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: var(--radio-inner-size);
  height: var(--radio-inner-size);
  background-color: var(--color-success);
  border-radius: var(--border-radius-circle);
  transition: var(--transition-base);
}

.urlInput,
.descriptionInput {
  padding: var(--spacing-md);
  font-size: var(--font-size-text);
  border: 2px solid var(--color-success);
  border-radius: var(--input-border-radius);
  font-family: var(--font-family);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.urlInput:focus,
.descriptionInput:focus {
  outline: none;
  box-shadow: var(--box-shadow);
  border-color: var(--color-success);
}

.descriptionInput {
  resize: vertical;
  min-height: 100px;
}

.submitButton {
  background-color: var(--color-success);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
  font-weight: 700;
  border: none;
  border-radius: var(--card-border-radius);
  height: var(--clients-input-height);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-base);
}

.submitButton:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.submitButton:disabled {
  background-color: var(--border-color);
  cursor: not-allowed;
}

.progressScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 245, 248, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-lg);
  z-index: var(--z-index-modal);
  backdrop-filter: blur(5px);
}

.spinner {
  border: var(--spinner-border-width) solid var(--bg-light);
  border-top: var(--spinner-border-width) solid var(--color-success);
  border-radius: var(--border-radius-circle);
  width: var(--clients-input-height);
  height: var(--clients-input-height);
  animation: spin var(--transition-speed) linear infinite;
  box-shadow: var(--box-shadow);
}

.errorMessage {
  color: var(--color-error);
  font-size: var(--font-size-lg);
  font-family: var(--font-family);
  background-color: var(--color-error-bg);
  padding: var(--spacing-md) var(--spacing-lg);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 883px) {
  .containerCheckProfile {
    padding: var(--spacing-md);
  }

  .title {
    font-size: var(--font-size-lg);
  }

  .text {
    font-size: var(--font-size-base);
  }

  .custom-radio {
    font-size: var(--font-size-base);
  }

  .urlInput,
  .descriptionInput {
    font-size: var(--font-size-base);
  }

  .submitButton {
    font-size: var(--font-size-text);
  }

  .errorMessage {
    font-size: var(--font-size-base);
    padding: var(--spacing-sm) var(--spacing-md);
  }
}
