/* Container principal */
.clients-container {
  padding: var(--spacing-lg);
  background-color: var(--color-bg);
  min-height: calc(100vh - var(--header-height));
}

/* Card principal */
.clients-card {
  background-color: var(--color-white);
  border-radius: var(--card-border-radius);
  width: 100%;
}

.clients-card-content {
  padding: var(--spacing-lg);
  width: 100%;
}

/* Botões da tabela */
.button-success {
  background-color: var(--color-success) !important;
  color: var(--color-primary) !important;
  border: none !important;
  min-width: 80px !important;
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  padding: 0.25rem 0.5rem !important;
}

/* Centering the "Ver mais" button */
.table-cell-actions {
  text-align: center !important;
}

.table-cell-actions .button-success {
  margin: 0 auto !important;
}

/* Responsividade */
@media (max-width: 768px) {
  .clients-container {
    padding: var(--spacing-md);
  }
}
