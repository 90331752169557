.geist-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-md) 0;
}

.geist-pagination-info {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  color: var(--color-secondary);
  font-size: var(--font-size-text);
  position: relative;
}

.geist-pagination-select {
  appearance: none;
  padding: 8px var(--spacing-md);
  padding-right: calc(var(--spacing-md) * 2);
  background-color: var(--color-white);
  border: 1px solid var(--color-bg);
  border-radius: var(--card-border-radius);
  color: var(--color-primary);
  font-size: var(--font-size-text);
  font-family: var(--font-family);
  cursor: pointer;
  transition: var(--transition-base);
  min-width: 80px;
}

.geist-pagination-select:hover {
  background-color: var(--bg-light);
}

.geist-pagination-select-icon {
  position: absolute;
  right: var(--spacing-md);
  width: 14px;
  height: 14px;
  opacity: 0.6;
  pointer-events: none;
}

.geist-pagination-controls {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
}

.geist-pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px var(--spacing-md);
  background-color: var(--color-white);
  border: 1px solid var(--color-bg);
  border-radius: var(--card-border-radius);
  color: var(--color-primary);
  font-size: var(--font-size-text);
  font-family: var(--font-family);
  cursor: pointer;
  transition: var(--transition-base);
}

.geist-pagination-button:hover:not(:disabled) {
  background-color: var(--bg-light);
}

.geist-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.geist-pagination-pages {
  color: var(--color-secondary);
  font-size: var(--font-size-text);
  padding: 0 var(--spacing-md);
}
