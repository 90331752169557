@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerEditCreditCard {
  flex: 1;
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  margin: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  font-family: var(--font-family);
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerEditCreditCard:hover {
  box-shadow: var(--box-shadow-hover);
}

.containerEditCreditCard .title {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  font-size: var(--font-size-large-text);
  font-weight: 700;
  color: var(--color-primary);
  line-height: var(--line-height);
}

.containerEditCreditCard .subTitle {
  font-size: var(--font-size-lg);
  font-weight: 400;
  margin-top: var(--spacing-md);
  color: var(--color-secondary);
  line-height: var(--line-height);
}

.containerEditCreditCard .containerCard {
  display: flex;
  gap: var(--spacing-lg);
  flex: 1;
  margin-top: var(--spacing-lg);
}

.containerEditCreditCard .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-bg);
  padding: var(--spacing-lg);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerEditCreditCard .containerCard .card:hover {
  background-color: var(--bg-light);
  box-shadow: var(--box-shadow);
  transform: translateY(-2px);
}

.containerEditCreditCard .containerCard .card .containerInput .title {
  font-size: var(--font-size-title);
  color: var(--color-primary);
  font-weight: 700;
}

.containerEditCreditCard .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  height: 140px;
}

.containerEditCreditCard .containerCard .card .containerInput .inputEditCreditCard {
  width: 100%;
  height: var(--clients-input-height);
  border-radius: var(--card-border-radius);
  background-color: var(--color-white);
  border: 2px solid transparent;
  padding: var(--spacing-md);
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-primary);
  font-family: var(--font-family);
  transition: var(--transition-base);
}

.containerEditCreditCard .containerCard .card .containerInput .inputEditCreditCard:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
}

.containerEditCreditCard .containerCard .line {
  border: 1px solid var(--border-color);
}

.containerEditCreditCard .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-lg);
}

.containerEditCreditCard .buttonNextEditCreditCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-success);
  font-size: var(--font-size-title);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--spacing-md);
  align-self: flex-end;
  cursor: pointer;
  border: none;
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerEditCreditCard .buttonNextEditCreditCard:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerEditCreditCard .buttonBackEditCreditCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-white);
  font-size: var(--font-size-title);
  font-weight: 700;
  border-radius: var(--card-border-radius);
  gap: var(--spacing-md);
  align-self: flex-end;
  cursor: pointer;
  border: 2px solid var(--color-success);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerEditCreditCard .buttonBackEditCreditCard:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.containerEditCreditCard .buttonBackEditCreditCard img {
  transform: rotate(-180deg);
  transition: var(--transition-base);
}

.containerEditCreditCard .buttonBackEditCreditCard:hover img {
  transform: rotate(-180deg) translateX(var(--spacing-sm));
}

@media only screen and (max-width: 842px) {
  .containerEditCreditCard {
    padding: var(--spacing-md);
    margin: var(--spacing-md);
    gap: var(--spacing-md);
  }

  .containerEditCreditCard .title {
    font-size: var(--font-size-title);
  }
  
  .containerEditCreditCard .subTitle {
    font-size: var(--font-size-text);
  }
  
  .containerEditCreditCard .containerCard {
    gap: var(--spacing-md);
    flex-direction: column;
  }
  
  .containerEditCreditCard .containerCard .card {
    gap: var(--spacing-md);
    padding: var(--spacing-md);
  }
  
  .containerEditCreditCard .containerCard .card .containerInput .title {
    font-size: var(--font-size-lg);
  }
  
  .containerEditCreditCard .containerCard .card .containerInput {
    gap: var(--spacing-md);
    height: var(--card-height-mobile);
  }
  
  .containerEditCreditCard .containerCard .card .containerInput .inputEditCreditCard {
    height: var(--card-height-mobile);
    font-size: var(--font-size-lg);
  }
  
  .containerEditCreditCard .containerCard .line {
    display: none;
  }
  
  .containerEditCreditCard .containerButtonsSumary {
    justify-content: center;
    flex-direction: column-reverse;
    width: 100%;
  }
  
  .containerEditCreditCard .buttonNextEditCreditCard,
  .containerEditCreditCard .buttonBackEditCreditCard {
    font-size: var(--font-size-lg);
    width: 100%;
  }
}
