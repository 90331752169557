.containerStep1 {
  padding: var(--spacing-lg);
  background-color: var(--color-white);
  border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
  gap: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  box-shadow: var(--box-shadow);
  transition: var(--transition-base);
}

.containerStep1:hover {
  box-shadow: var(--box-shadow-hover);
}

@media only screen and (max-width: 1153px) {
  .containerStep1 {
    padding: var(--spacing-md);
    gap: var(--spacing-md);
  }
}
