@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAutoMensagens {
  padding: 40px;
  font-family: 'Outfit', sans-serif;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #1E293B;
}

.subTitleInput {
  font-size: 14px;
  color: #64748B;
  margin-bottom: 12px;
}

.cardContent {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 32px;
  margin-top: 24px;
}

.inputField {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  font-size: 16px;
  color: #1E293B;
  margin-bottom: 24px;
}

.inputField:focus {
  outline: none;
  border-color: #10B981;
}

.toogleButtom {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
}

.textToogle {
  font-size: 14px;
  color: #64748B;
  cursor: pointer;
}

.dias-container {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.dia-label {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  color: #64748B;
  cursor: pointer;
  transition: all 0.2s;
}

.dia-label.selecionado {
  background: #10B981;
  border-color: #10B981;
  color: white;
}

.checkbox-dia {
  display: none;
}

.custom-timepickerAuto {
  width: 100%;
}

.custom-timepickerAuto .MuiInputBase-root {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #E2E8F0;
}

.buttonAddAuto {
  background: #10B981;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: auto;
  display: inline-block;
}

.buttonAddAuto:hover {
  background: #059669;
}

.containerFollowUP .containerInputsClients {
  min-height: var(--clients-input-height);
}

.containerFollowUP .containerInputsClients .inputSearch {
  width: 100%;
  height: var(--clients-input-height);
  font-family: var(--font-family);
  color: var(--color-primary);
  font-size: var(--font-size-lg);
  padding: var(--card-padding);
  border-radius: var(--input-border-radius);
  border: 2px solid transparent;
  background-color: var(--color-bg);
  transition: var(--transition-base);
}

.containerFollowUP .containerInputsClients .inputSearch:focus {
  outline: none;
  border-color: var(--color-success);
  box-shadow: var(--box-shadow);
  background-color: var(--bg-light);
}

.containerFollowUP .cardClients {
  display: flex;
  flex-direction: column;
  gap: var(--card-gap);
  min-height: 60vh;
  overflow: auto;
}

.containerFollowUP .cardClients .header,
.containerFollowUP .cardClients .containerItens .client {
  display: flex;
  width: 100%;
  height: var(--clients-card-height);
  transition: var(--transition-base);
}

.containerFollowUP .cardClients .containerItens .client:hover {
  background-color: var(--bg-light);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
}

.containerFollowUP .cardClients .header div,
.containerFollowUP .cardClients .containerItens .client div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--card-gap) 0;
  text-align: center;
  flex: 1;
}

.containerFollowUP .cardClients .containerItens .client div {
  padding: var(--spacing-sm);
}

.containerFollowUP .cardClients .header div {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
}

.containerFollowUP .cardClients .header .containerIconFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--card-gap);
}

.containerFollowUP .cardClients .header .containerIconFilter img {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  transition: var(--transition-base);
}

.containerFollowUP .cardClients .header .containerIconFilter img:hover {
  transform: scale(1.1);
}

.containerFollowUP .cardClients .header .containerIconFilter button {
  cursor: pointer;
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  background-color: var(--color-error);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  font-weight: 700;
  border-radius: var(--border-radius);
  font-size: var(--font-size-lg);
  transition: var(--transition-base);
}

.containerFollowUP .cardClients .header .containerIconFilter button:hover {
  opacity: var(--hover-opacity);
  transform: scale(1.1);
}

.containerFollowUP .cardClients .containerItens .client div {
  font-size: var(--font-size-title);
  font-weight: 400;
  color: var(--color-secondary);
}

.containerFollowUP .cardClients .containerItens .buttonMoreCheck {
  max-width: var(--clients-button-max-width);
  border-radius: var(--card-border-radius);
  background-color: var(--color-success);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-lg);
  font-weight: 700;
  cursor: pointer;
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerFollowUP .cardClients .containerItens .buttonMoreCheck:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerFollowUP .buttonAddAuto {
  border-radius: var(--card-border-radius);
  background-color: var(--color-success);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-text);
  font-weight: 700;
  cursor: pointer;
  padding: var(--spacing-sm) var(--card-padding);
  margin-bottom: var(--card-gap);
  margin-top: var(--spacing-lg);
  color: var(--color-primary);
  transition: var(--transition-base);
}

.containerFollowUP .buttonAddAuto:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
  opacity: var(--hover-opacity);
}

.containerFollowUP .cardClients .containerItens .containerName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerFollowUP .cardClients .containerItens .containerName .userIMage {
  width: var(--clients-user-image-size);
  height: var(--clients-user-image-size);
  border-radius: var(--border-radius-circle);
  object-fit: cover;
  transition: var(--transition-base);
}

.containerFollowUP .cardClients .containerItens .containerName .userIMage:hover {
  transform: scale(1.1);
  box-shadow: var(--box-shadow-hover);
}

.containerFollowUP .containerPagination {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-title);
  font-weight: 400;
  margin-top: var(--spacing-lg);
}

.containerFollowUP .containerPagination .pagination {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  width: 400px;
  justify-content: space-between;
}

.containerFollowUP .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  cursor: pointer;
  font-size: var(--font-size-title);
  font-weight: 700;
  font-family: var(--font-family);
  color: var(--color-primary);
  background-color: var(--color-white);
  border: 2px solid var(--color-success);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  transition: var(--transition-base);
}

.containerFollowUP .pagination .containerButton:hover {
  background-color: var(--color-success-bg);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-hover);
}

.containerFollowUP .pagination .containerButton .left {
  transform: rotate(-180deg);
  transition: var(--transition-base);
}

.containerFollowUP .pagination .containerButton:hover .left {
  transform: rotate(-180deg) translateX(var(--spacing-sm));
}

.containerFollowUP .containerPagination .totalPage {
  display: flex;
  gap: var(--card-gap);
  align-items: center;
}

.containerFollowUP .containerPagination .totalPage .text {
  font-size: var(--font-size-title);
  font-weight: 700;
  color: var(--color-primary);
}

@media only screen and (max-width: 1920px) {
  .containerFollowUP {
    padding: 0 var(--spacing-lg);
  }

  .containerFollowUP .containerInputsClients .inputSearch {
    height: var(--clients-input-height);
    font-size: var(--font-size-text);
  }

  .containerFollowUP .cardClients .header div {
    font-size: var(--font-size-title);
  }

  .containerFollowUP .cardClients .containerItens .client div {
    font-size: var(--font-size-lg);
  }

  .containerFollowUP .cardClients .containerItens .buttonMoreCheck {
    height: var(--card-height-mobile);
    font-size: var(--font-size-lg);
  }

  .containerFollowUP .cardClients .containerItens .containerName .userIMage {
    width: var(--clients-user-image-size);
    height: var(--clients-user-image-size);
  }

  .containerFollowUP .containerPagination {
    font-size: var(--font-size-text);
  }

  .containerFollowUP .containerPagination .pagination {
    gap: var(--spacing-md);
  }

  .containerFollowUP .pagination .containerButton {
    font-size: var(--font-size-text);
  }

  .containerFollowUP .containerPagination .totalPage .text {
    font-size: var(--font-size-lg);
  }
}

@media only screen and (max-width: 1437px) {
  .containerFollowUP {
    padding: var(--spacing-sm) var(--spacing-md);
  }

  .containerFollowUP .cardClients .header div,
  .containerFollowUP .cardClients .containerItens .client div {
    flex: none;
    width: 200px;
  }

  .containerFollowUP .containerInputsClients .inputSearch {
    height: var(--card-height-mobile);
    font-size: var(--font-size-base);
  }

  .containerFollowUP .cardClients .header div {
    font-size: var(--font-size-lg);
  }

  .containerFollowUP .cardClients .containerItens .client div {
    font-size: var(--font-size-text);
  }

  .containerFollowUP .cardClients .containerItens .buttonMoreCheck {
    height: var(--card-height-mobile);
    font-size: var(--font-size-text);
  }
}

@media only screen and (max-width: 720px) {
  .containerFollowUP {
    padding: var(--spacing-md);
  }

  .containerFollowUP .containerPagination {
    font-size: var(--font-size-base);
    flex-direction: column;
    gap: var(--spacing-lg);
    justify-content: center;
  }

  .containerFollowUP .pagination .containerButton {
    font-size: var(--font-size-base);
  }

  .containerFollowUP .containerPagination .pagination {
    gap: var(--card-gap);
    width: 90vw;
  }

  .containerFollowUP .containerPagination .totalPage .text {
    font-size: var(--font-size-text);
  }

  .containerFollowUP .containerPagination .totalPage {
    justify-content: flex-end;
  }
}
