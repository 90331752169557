@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAttendantSales {
    padding: var(--spacing-lg);
    font-family: var(--font-family);
    color: var(--color-primary);
}

.containerInfoSimulator {
    padding: var(--spacing-lg);
    background-color: var(--color-white);
    border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);
    gap: var(--spacing-md);
    display: flex;
    flex-direction: column;
    color: var(--color-error);
    box-shadow: var(--box-shadow);
}

.containerInfoSimulator strong {
    font-size: var(--font-size-lg);
    line-height: var(--line-height);
}

.containerAttendantSales .titlePage {
    display: none;
}

.containerAttendantSales .contentMobile {
    display: none;
}

.containerAttendantSales .containerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.containerAttendantSales .containerMenu .menu {
    flex: 1;
    text-align: center;
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: var(--font-size-lg);
    font-weight: 400;
    cursor: pointer;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: var(--transition-base);
}

.containerAttendantSales .containerMenu .menu:hover:not(.disabledMenus) {
    background-color: var(--bg-light);
}

.containerAttendantSales .disabledMenus {
    color: var(--border-color) !important;
    cursor: not-allowed;
}

.containerAttendantSales .containerMenu .menuSelected {
    background: var(--color-white);
    border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
    box-shadow: var(--box-shadow);
}

.containerAttendantSales .containerMenu .line {
    width: 2px;
    height: 23px;
    background: var(--border-color);
}

.containerAttendantSales .containerMenu .none {
    background-color: transparent !important;
}

.containerAttendantSales .containerInput {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
}

.containerAttendantSales .containerInput .title {
    display: flex;
    align-items: center;
    font-size: var(--font-size-title);
    font-weight: 700;
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerAttendantSales .containerTitleDIv .title {
    display: flex;
    align-items: center;
    font-size: var(--font-size-title);
    font-weight: 700;
    color: var(--color-primary);
    line-height: var(--line-height);
}

.containerAttendantSales .containerTitleDIv .line {
    border: 1px solid var(--color-primary);
    padding: 0 0 0 var(--spacing-lg);
}

.containerAttendantSales .containerTitleDIv .sliderCOntainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerAttendantSales .cardChatHistory {
    background-color: var(--color-white);
    padding: var(--spacing-lg);
    border-radius: var(--card-border-radius);
    font-size: var(--font-size-title);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    position: relative;
    margin-bottom: var(--spacing-lg);
    word-break: break-all;
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerAttendantSales .cardChatHistory:hover {
    box-shadow: var(--box-shadow-hover);
}

.containerAttendantSales .cardChatHistory .title {
    font-weight: 700;
    font-size: var(--font-size-large-text);
    align-self: flex-start;
    margin-bottom: var(--spacing-md);
    color: var(--color-primary);
}

.containerAttendantSales .cardChatHistory .containerImg {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 160px;
    border-radius: var(--border-radius-circle);
    overflow: hidden;
    border: var(--spacing-sm) solid var(--color-white);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    transition: var(--transition-base);
}

.containerAttendantSales .cardChatHistory .containerImg:hover {
    transform: translateX(-50%) scale(1.05);
    box-shadow: var(--box-shadow-hover);
}

.containerAttendantSales .containerInput .subTitleInput {
    font-size: var(--font-size-text);
    font-weight: 400;
    color: var(--border-color);
    margin-bottom: var(--spacing-md);
}

.containerAttendantSales .containerInput .inputStyle {
    width: 100%;
    height: var(--clients-input-height);
    border-radius: var(--card-border-radius);
    background-color: var(--color-bg);
    border: 2px solid transparent;
    padding: var(--spacing-md);
    font-size: var(--font-size-title);
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: var(--font-family);
    color: var(--color-primary);
    transition: var(--transition-base);
}

.containerAttendantSales .containerInput .inputStyle:focus {
    outline: none;
    border-color: var(--color-success);
    box-shadow: var(--box-shadow);
}

.containerAttendantSales .containerButtons {
    display: flex;
    gap: var(--spacing-lg);
    align-self: flex-end;
}

.containerAttendantSales .containerButtons .buttonSimulator,
.containerAttendantSales .containerButtons .buttonSave,
.containerAttendantSales .containerButtons .buttonReset {
    font-size: var(--font-size-title);
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--card-border-radius);
    gap: var(--spacing-md);
    cursor: pointer;
    width: 240px;
    height: var(--clients-input-height);
    transition: var(--transition-base);
}

.containerAttendantSales .containerButtons .buttonSimulator {
    border: 2px solid var(--color-success);
    background-color: var(--color-white);
}

.containerAttendantSales .containerButtons .buttonSimulator:hover {
    background-color: var(--color-success-bg);
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
}

.containerAttendantSales .containerButtons .buttonSave {
    background-color: var(--color-success);
    border: none;
}

.containerAttendantSales .containerButtons .buttonSave:hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

.containerAttendantSales .containerButtons .buttonReset {
    background-color: var(--color-error);
    color: var(--color-white);
    border: none;
}

.containerAttendantSales .containerButtons .buttonReset:hover {
    transform: translateY(-2px);
    box-shadow: var(--box-shadow-hover);
    opacity: var(--hover-opacity);
}

@media only screen and (max-width: 1500px) {
    .containerAttendantSales {
        padding: var(--spacing-lg);
    }

    .containerAttendantSales .cardChatHistory {
        font-size: var(--font-size-text);
    }

    .containerAttendantSales .cardChatHistory .title {
        font-size: var(--font-size-lg);
    }

    .containerAttendantSales .containerMenu .menu {
        font-size: var(--font-size-text);
    }

    .containerAttendantSales .containerInput .title {
        font-size: var(--font-size-lg);
    }

    .containerAttendantSales .containerInput .subTitleInput {
        font-size: var(--font-size-base);
    }

    .containerAttendantSales .containerInput .inputStyle {
        height: var(--clients-input-height);
        font-size: var(--font-size-lg);
    }

    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: var(--font-size-lg);
        width: 200px;
        height: var(--clients-input-height);
    }
}

@media only screen and (max-width: 1431px) {
    .containerAttendantSales .containerMenu .menu {
        font-size: var(--font-size-base);
    }

    .containerAttendantSales .containerInput .title {
        font-size: var(--font-size-text);
    }

    .containerAttendantSales .containerInput .subTitleInput {
        font-size: var(--font-size-sm);
    }

    .containerAttendantSales .containerInput .inputStyle {
        height: var(--card-height-mobile);
        font-size: var(--font-size-text);
    }

    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: var(--font-size-text);
        width: 180px;
        height: var(--card-height-mobile);
    }
}

@media only screen and (max-width: 1153px) {
    .containerAttendantSales .contentWeb {
        display: none;
    }

    .containerAttendantSales .contentMobile {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-lg);
    }

    .containerAttendantSales .containerMenu {
        display: none;
    }

    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: var(--font-size-sm);
        width: 140px;
        height: var(--card-height-mobile);
    }

    .containerAttendantSales .containerButtons .buttonSave img {
        width: var(--button-icon-width);
    }

    .containerAttendantSales .titlePage {
        display: block;
        font-size: var(--font-size-lg);
        font-weight: 700;
        color: var(--color-primary);
    }

    .containerAttendantSales .containerButtons {
        align-self: center;
    }

    .containerAttendantSales .cardChatHistory {
        font-size: var(--font-size-text);
    }

    .containerAttendantSales .cardChatHistory .title {
        font-size: var(--font-size-lg);
    }
}
