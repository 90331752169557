@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.contentWeb {
  cursor: default;
}

.contentMobile {
  display: none;
}

@media only screen and (max-width: 1153px) {
  .contentWeb {
      display: none;
  }
.contentMobile {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-lg);
}

}