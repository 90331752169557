@import "../../theme/variables.css";

.topbar {
  width: calc(100% - var(--sidebar-width));
  height: var(--header-height);
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-lg);
  position: fixed;
  top: 0;
  left: var(--sidebar-width);
  z-index: var(--z-index-header);
  box-shadow: var(--box-shadow);
}

.containerTitlePage {
  display: flex;
  gap: var(--spacing-lg);
  align-items: center;
}

.containerTitlePage .titleTop {
  font-family: var(--font-family);
  font-size: var(--font-size-title);
  font-weight: 700;
  line-height: var(--line-height);
  color: var(--color-primary);
}

.containerRigthTop {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  margin: 0;
  text-transform: capitalize;
  position: relative;
}

.containerRigthTop .imgTOp {
  width: var(--clients-icon-btn-size);
  /* height: var(--clients-icon-btn-size); */
}

.containerRigthTop .userIMage {
  width: var(--clients-user-image-size);
  height: var(--clients-user-image-size);
  border-radius: var(--border-radius-circle);
  object-fit: cover;
  cursor: pointer;
}

.containerRigthTop .nameText {
  font-family: var(--font-family);
  font-size: var(--font-size-lg);
  font-weight: 700;
  cursor: pointer;
  color: var(--color-primary);
}

.containerRigthTop .subText {
  font-family: var(--font-family);
  font-size: var(--font-size-lg);
  font-weight: 400;
  cursor: pointer;
  color: var(--color-primary);
}

.containerStatusCheck {
  display: flex;
  gap: var(--spacing-md);
  font-size: var(--font-size-base);
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--color-primary);
  text-transform: lowercase;
}

.containerStatusCheck .true,
.containerStatusCheck .false {
  padding: var(--spacing-sm) var(--spacing-md);
  gap: var(--spacing-sm);
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}

.containerStatusCheck .true {
  background-color: var(--color-success-bg);
}

.containerStatusCheck .false {
  background-color: var(--color-error-bg);
}

.containerStatusCheck .cicle {
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: var(--border-radius-circle);
}

.containerStatusCheck .true .cicle {
  background-color: var(--color-success);
}

.containerStatusCheck .false .cicle {
  background-color: var(--color-error);
}

.iconNotifica {
  position: absolute;
  
  display: flex;
  background: var(--notification-gradient);
  color: var(--color-white);
  width: var(--notification-size);
  height: var(--notification-size);
  border-radius: var(--border-radius-circle);
  border: 2px solid var(--color-white);
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: var(--line-height);
  box-shadow: var(--box-shadow);
  padding: var(--spacing-sm);
  transition: var(--transition-base);
  font-size: var(--font-size-sm) !important;
}

.containerStatusCheckMobiles,
.containerHeaderMobile {
  display: none;
}

@media only screen and (max-width: 900px) {
  .topbar {
    padding: 0 var(--spacing-lg);
    gap: var(--spacing-lg);
    width: 100%;
    left: 0;
  }

  .containerTitlePage {
    gap: var(--spacing-sm);
  }

  .containerTitlePage .titleTop {
    font-size: var(--font-size-lg);
    line-height: var(--line-height);
  }

  .containerRigthTop {
    gap: var(--spacing-md);
  }

  .containerRigthTop .imgTOp {
    width: var(--clients-icon-btn-size);
    height: var(--clients-icon-btn-size);
  }

  .containerRigthTop .userIMage {
    width: var(--clients-user-image-size);
    height: var(--clients-user-image-size);
  }

  /* Hide text and status on mobile */
  .containerRigthTop .nameText,
  .containerRigthTop .subText,
  .containerStatusCheck {
    display: none;
  }

  .iconNotifica {
    right: calc(var(--spacing-md) * -1);
    top: calc(var(--spacing-sm) * -1);
  }

  .containerHeaderMobile,
  .containerStatusCheckMobiles {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-md);
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}
