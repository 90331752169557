@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import "../../theme/variables.css";

.sidebar {
  background-color: var(--color-dark);
  width: var(--sidebar-width);
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  z-index: var(--z-index-main);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  color: var(--color-white);
}

/* Logo section */
.logoSideBar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--color-secondary);
  padding-left: var(--spacing-lg) !important;
  width: 100%;
  height: var(--header-height);
}

/* Separator */
.lineMenu {
  width: 80%;
  height: 2px;
  background-color: var(--color-primary);
  margin: 0 var(--spacing-lg);
}

/* Menu text */
.menu-text {
  color: var(--color-white);
  line-height: var(--line-height);
}

/* Menu link container */
.containerMenuLink {
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin: var(--spacing-sm) var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-lg);
  color: var(--color-white);
}

.containerMenuLink .textMenu {
  font-size: var(--font-size-base);
  font-weight: 400;
}

.containerMenuLink .imgMenu {
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
  display: flex;
  align-items: center;
}

/* Selected menu state */
.containerMenuLink.selectedMenu {
  margin: var(--spacing-sm) var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-lg);
  background-color: var(--color-primary);
  border-radius: var(--card-border-radius);
}

.containerMenuLink.selectedMenu .textMenu {
  font-weight: 700;
}

/* Logout menu */
.menuSair {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-secondary);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: var(--font-size-base);
}

/* Mobile/hamburger buttons */
.menu-icon {
  display: none;
  position: absolute;
  width: var(--clients-input-height);
  right: var(--spacing-md);
  top: 0;
}

.btn {
  position: absolute;
  width: var(--clients-input-height);
  height: var(--clients-input-height);
  top: var(--spacing-md);
  left: 0;
  cursor: pointer;
}

.btn-left,
.btn-right {
  position: absolute;
  width: var(--spacing-md);
  height: var(--spacing-sm);
  top: var(--spacing-lg);
  transition: var(--transition-base);
  background-color: var(--color-line);
}

.btn-left {
  left: 0;
}

.btn-right {
  left: var(--spacing-md);
}

.btn-left::before,
.btn-left::after,
.btn-right::before,
.btn-right::after {
  content: "";
  position: absolute;
  width: var(--spacing-md);
  height: var(--spacing-sm);
  background-color: var(--color-line);
  transition: var(--transition-base);
}

.btn-left::before,
.btn-right::before {
  top: calc(var(--spacing-md) * -1.5);
}

.btn-left::after,
.btn-right::after {
  top: var(--spacing-md);
}

.active .btn-left,
.active .btn-right {
  background: transparent !important;
}

.active .btn-left::before {
  transform: rotate(45deg) scaleX(1.4) translate(4px, 4px);
}

.active .btn-left::after {
  transform: rotate(-45deg) scaleX(1.4) translate(4px, -4px);
}

.active .btn-right::before {
  transform: rotate(-45deg) scaleX(1.4) translate(-4px, 4px);
}

.active .btn-right::after {
  transform: rotate(45deg) scaleX(1.4) translate(-4px, -4px);
}

/* Submenu styles */
.subMenuContainer {
  padding-left: var(--spacing-lg);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.subMenuLink .textMenu {
  font-size: var(--font-size-base);
  color: var(--color-white);
}

.containerMenuLink.subMenuLink {
  padding: var(--spacing-md);
  background-color: var(--color-secondary);
  border-radius: var(--border-radius);
  margin: 0 var(--spacing-lg) 0 0;
}

.containerMenuLink.subMenuLink:hover {
  background-color: var(--color-primary);
}

.expandIcon {
  width: var(--clients-icon-btn-size);
  height: var(--clients-icon-btn-size);
}

/* Mobile styles */
@media (max-width: 900px) {
  .sidebar {
    width: 280px;
    position: absolute;
    right: 0;
    z-index: var(--z-index-modal);
  }

  .containerSideBar {
    display: flex;
  }

  .containerSideBar .blur {
    background-color: var(--overlay-color);
    width: 880px;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(5px);
    z-index: var(--z-index-modal);
  }

  .logoSideBar {
    background-color: var(--color-secondary);
  }

  .logoSideBar img,
  .containerStatusCheckMobiles {
    display: none;
  }

  .menu-icon {
    display: flex;
  }

  .containerStatusCheckMobiles {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    font-size: var(--font-size-base);
    font-family: var(--font-family);
    color: var(--color-white);
  }

  .containerStatusCheckMobiles .true,
  .containerStatusCheckMobiles .false {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    height: var(--notification-size);
    margin: var(--spacing-sm) var(--spacing-lg);
    padding: var(--spacing-md);
    max-width: 150px;
    border: 1px solid;
    border-radius: var(--border-radius);
    background-color: transparent;
  }

  .containerStatusCheckMobiles .true {
    border-color: var(--color-success-bg);
  }

  .containerStatusCheckMobiles .false {
    border-color: var(--color-error-bg);
  }

  .containerStatusCheckMobiles .cicle {
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--border-radius-circle);
  }

  .containerStatusCheckMobiles .true .cicle {
    background-color: var(--color-success);
  }

  .containerStatusCheckMobiles .false .cicle {
    background-color: var(--color-error);
  }

  .containerMenuLink {
    margin: var(--spacing-md) var(--spacing-lg);
    padding: var(--spacing-md);
  }

  .containerMenuLink .textMenu,
  .menuSair {
    font-size: var(--font-size-sm);
  }
}
